import axios, { AxiosError } from 'axios';
import { mainURL, mapBoxAccessToken } from '../config';
import { DeliveryEnumType } from '../components/cart-item/CartItem';
import { addSavedCardAC } from '../state/user-reducer';
import { ProductStateType, ProductType } from '../state/product-reducer';
import { CategoryType } from '../state/category-reducer';
import { SubcategoryType } from '../state/subcategory-reducer';


export const verifyPhoneNumber = async (phoneNumber: string) => {
  try {
    const response = await axios.post(
      mainURL + '/en/api/v1/send-login-code/',
      { phone_number: phoneNumber },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Response:', response.data);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data?.message || 'Login failed');
    } else {
      throw new Error('Login failed: Network or server error');
    }
  }
};

export const loginByCode = async (
  phoneNumber: string,
  confirmationCode: string
) => {
  try {
    const response = await axios.post(
      mainURL + '/en/api/v1/login-by-code/',
      { password: confirmationCode, phone_number: phoneNumber },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    localStorage.setItem('accessToken', response.data.access);
    localStorage.setItem('refreshToken', response.data.refresh);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data?.message || 'Verification failed');
    } else {
      throw new Error('Verification failed: Network or server error');
    }
  }
};

export const sendRegisterRequest = async (
  username: string,
  phoneNumber: string,
  city: string
) => {
  try {
    const response = await fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, phoneNumber, city }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Registration failed');
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    throw new Error(
      error instanceof Error ? error.message : 'Registration failed'
    );
  }
};

export const makePaymentRequest = async (
  cryptogram: string,
  cardName: string,
  orderId: number
): Promise<PaymentResponse | { CardHolderMessage: string; Reason: string; Status: string }> => {
  try {
    const token = localStorage.getItem('accessToken');
    const orderData = {
      order_id: orderId,
      card_cryptogram: cryptogram,
      name: cardName,
      save_card: true,
    };

    console.log('order data payment', orderData);

    const response = await axios.post<PaymentResponse>(
      `${mainURL}/en/api/v1/shops/cloudpayments-checkout/`,
      orderData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Payment processed successfully:', response);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<any>;

      if (axiosError.response?.status === 303) {
        console.log('Redirection URL:', axiosError.response.headers.location);
      }

      console.error('Error processing payment:', axiosError);

      // Extract error details from the response or set default values
      const errorDetails = {
        CardHolderMessage: axiosError.response?.data?.detail?.CardHolderMessage || 'Ошибка при оплате',
        Reason: axiosError.response?.data?.detail?.Reason || 'Unknown reason',
        Status: axiosError.response?.data?.detail?.Status || 'Failed',
      };

      return errorDetails;
    } else {
      console.error('Unexpected error:', error);
      throw new Error('Payment failed: Network or server error');
    }
  }
};

export const makePaymentRequestWithSavedCard = async (
  orderId: number,
  savedCardId: number
) => {
  try {
    const token = localStorage.getItem('accessToken');
    const orderData = {
      order_id: orderId,
      saved_card_id: savedCardId,
    };

    console.log('order data payment', orderData);

    const response = await axios.post(
      mainURL + '/en/api/v1/shops/cloudpayments-checkout/',
      orderData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Payment processed successfully:', response);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<any>;

      if (axiosError.response?.status === 303) {
        console.log('Redirection URL:', axiosError.response.headers.location);
      }

      console.error('Error processing payment:', axiosError);

      // Extract error details from the response or set default values
      const errorDetails = {
        CardHolderMessage: axiosError.response?.data?.detail?.CardHolderMessage || 'Ошибка при оплате',
        Reason: axiosError.response?.data?.detail?.Reason || 'Unknown reason',
        Status: axiosError.response?.data?.detail?.Status || 'Failed',
      };

      return errorDetails;
    } else {
      console.error('Unexpected error:', error);
      throw new Error('Payment failed: Network or server error');
    }
  }
};

const send3DSRequest = async (
  orderId: number,
  cardName: string,
  MD: string,
  PaRes: string
) => {
  try {
    const token = localStorage.getItem('accessToken');
    const requestData = {
      order_id: orderId,
      name: cardName,
      save_card: true,
      MD, // From the payment provider response
      PaRes, // From the payment provider response
    };

    console.log('3DS request data:', requestData);

    const response = await axios.post(
      mainURL + '/en/api/v1/shops/cloudpayments-post3ds/',
      requestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('3DS request successful:', response);
  } catch (error: any) {
    console.error(
      'Error in 3DS request:',
      error.response?.data || error.message
    );
  }
};

export const fetchWithAuthCheck = async (url: string, options: any) => {
  const requestUrl = `${mainURL}${url}`;
  const token = localStorage.getItem('accessToken');

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios(requestUrl, options);
      return response;
    } catch (error: any) {
      if (error.response?.status === 401) {
        // Attempt to refresh tokens
        const newTokensResponse = await updateTokens();
        if (newTokensResponse.success) {
          // Retry the original request with the new access token
          options.headers.Authorization = `Bearer ${localStorage.getItem(
            'accessToken'
          )}`;
          const newResponse = await axios(requestUrl, options);
          return newResponse;
        } else {
          throw new Error('Token refresh failed');
        }
      } else {
        throw error; // Rethrow the error for further handling
      }
    }
  } else {
    throw new Error('No access token found');
  }
};

export const updateTokens = async () => {
  try {
    const response = await axios.post(
      `${mainURL}/refresh-token/`,
      {
        refresh: localStorage.getItem('refreshToken'),
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      console.log('Token updated successfully', response);
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      return { success: true, message: 'Ok' };
    }
    return { success: false, message: response.statusText };
  } catch (e: any) {
    console.error(e);
    return { success: false, message: e.message };
  }
};

export const fetchAddressByLocation = async (lat: number, lng: number) => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    console.error('No access token found.');
    return { success: false, message: 'No access token found' };
  }

  try {
    const response = await axios.get(
      mainURL + `/en/api/v1/users/get-address-by-location/`,
      {
        params: {
          lat: lat,
          lng: lng,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Return the data received from the response
    return response.data.address;
  } catch (error) {
    console.error('Error fetching address by location:', error);
    return {
      success: false,
      message: error || 'Error fetching address by location',
    };
  }
};

export const getCardType = (bin: string) => {
  
  const cardTypes = {
    'visa': /^4[0-9]{0,}$/,
    'mastercard': /^(5[1-5][0-9]{4}|2[2-7][0-9]{4})$/,
    'amex': /^3[47][0-9]{4}$/,
    'discover': /^6(?:011|5[0-9]{2})[0-9]{3}$/,
    'maestro': /^(5018|5020|5038|6304|6759|676[1-3])$/,
    'paypal': /^7[0-9]{5}$/, // Example pattern (replace with real if necessary)
    // Add more patterns as needed
  };

  for (const [type, pattern] of Object.entries(cardTypes)) {
    if (pattern.test(bin)) {
      return type;
    }
  }

  return 'default'; // If no match found
};



export const getStatusOfOrder = (num: number) => {
  switch (num) {
      case 0: {
          return 'Заказ оплачен';
      }
          break;
      case 1: {
          return 'Заказ принят';
      }
          break;
      case 2: {
          return 'Заказ ждет курьера';
      }
          break;
      case 3: {
          return 'Доставка';
      }
          break;
      case 4: {
          return 'Доставлено';
      }
          break;
      case 5: {
          return 'Заказ в архиве';
      }
          break;
      case 6: {
          return 'Заказ отклонен пользователем';
      }
          break;
      case 7: {
          return 'Заказ отклонен магазином';
      }
          break;
      case 8: {
          return 'Просрочено';
      }
      default:
          break;
  }
};

export const getStatusClass = (status: number) => {
  if ([6, 7, 8].includes(status)) {
    return 'red';
  } else if (status === 5) {
    return 'gray';
  } else if ([0, 1, 2, 3, 4].includes(status)) {
    return 'green';
  } else {
    return ''; // Default class or empty if no specific color
  }
};


// Функция для фильтрации продуктов на основе активной категории и подкатегории
export const filterProducts = (
  products: ProductType[],
  category: CategoryType | null,
  subcategory: SubcategoryType | null
) => {
  if (!category || category.name === 'Популярное') return products;

  console.log('filterProducts', products);
  console.log('category', category);
  console.log('subcategory', subcategory);

  // Фильтруем продукты, проверяя соответствие активной категории и подкатегории
  const filtered = products.filter((product) =>
    product.categories.some((cat) => {
      const isCategoryMatch = cat.id === category.id;
      const isParentCategoryMatch = cat.parent_category_info?.id === category.id;
      if (subcategory) return isParentCategoryMatch && cat.id === subcategory.id;
      return isCategoryMatch || isParentCategoryMatch;
    })
  );

  return filtered;
};

export const sendFeedback = async (text: string) => {
  if (!localStorage.getItem('accessToken')) {
    console.error('No access token found.');
    return { success: false, message: 'No access token found' };
  }
  
  try {
    const response = await axios.post(
      mainURL + `/en/api/v1/users/application-feedbacks/`,
      {
        feedback: text,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    if (response.status === 200) {
      console.log('Feedback sent successfully', response);
      return { success: true, message: 'Ok' };
    }
    return { success: false, message: response.statusText };
  }catch(err) {
    console.error('Error sending feedback:', err);
    return { success: false, message: 'Error sending feedback' };
  }
}