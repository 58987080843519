import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveSubcategoryAC,
  SubcategoryType,
} from '../../state/subcategory-reducer';
import './category-item.css';
import { RootStateType } from '../../state/store';
import { useState } from 'react';

type SubcategoryItemPropsType = {
  subcategory: SubcategoryType;
  isActive: boolean;
};

const SubcategoryItem = (props: SubcategoryItemPropsType) => {
  const dispatch = useDispatch<any>();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };


  const handleChangeSubcategory = () => {
    // dispatch(getProductsBySubcategoryNameTC(props.subcategory.name));
    dispatch(setActiveSubcategoryAC(props.subcategory));
  };

  return (
    <div className="category-item">
      <img
        src={props.subcategory.image}
        alt=""
        onClick={handleChangeSubcategory}
        onLoad={handleImageLoad}
        className={`${props.isActive ? 'active' : ''} ${
          isLoaded ? 'visible' : 'hidden'
        }`}
        loading="lazy"
      />
      <p>{props.subcategory.name}</p>
    </div>
  );
};

export default SubcategoryItem;
