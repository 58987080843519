import { useEffect, useState } from 'react';
import './show-modal.css';
import LoginModal from './LoginModal';
import CreditCardModal from './CreditCardModal';
import TotalCostModal from './TotalCostModal'; // Импортируем новое модальное окно
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { changeModalTypeAC, modalEnumType } from '../../state/user-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import OrderTrackingModal from './OrderTrackingModal';

// Компонент ShowModal
const ShowModal = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const modalType = useSelector<RootStateType, modalEnumType>(
    (state) => state.userData.modalType
  );

  const [totalCost, setTotalCost] = useState(0); // Состояние для хранения общей стоимости
  const [showTotalCostModal, setShowTotalCostModal] = useState(true); // Управляем, показываем ли TotalCostModal

  // Функция для закрытия модального окна
  const closeModal = () => {
    dispatch(changeModalTypeAC(modalEnumType.NONE));
    setShowTotalCostModal(true); // Открываем TotalCostModal
    orderId && navigate(-1);
  };

  // Управление прокруткой страницы в зависимости от состояния модального окна
  useEffect(() => {
    document.body.style.overflow =
      modalType === modalEnumType.NONE ? 'auto' : 'hidden';
  }, [modalType]);

  // Функция для рендеринга содержимого модального окна
  const renderModalContent = () => {
    if (showTotalCostModal && modalType === modalEnumType.CREDIT_CARD) {
      // Если показываем TotalCostModal перед CreditCardModal
      return (
        <TotalCostModal
          onConfirm={() => setShowTotalCostModal(false)} // Закрываем TotalCostModal и открываем CreditCardModal
        />
      );
    }
    switch (modalType) {
      case modalEnumType.LOGIN:
        return <LoginModal />;
      case modalEnumType.CREDIT_CARD:
        return <CreditCardModal />;
      case modalEnumType.ORDER_TRACKING:
        return <OrderTrackingModal />;
      default:
        return null;
    }
  };

  // Получаем классы для модального окна и фона
  const modalClass =
    modalType !== modalEnumType.NONE ? 'modal open-modal' : 'modal';
  const modalWindowClass = `modal-window open-modal-window ${
    modalType === modalEnumType.LOGIN
      ? 'login-modal'
      : modalType === modalEnumType.REGISTER
      ? 'register-modal'
      : modalType === modalEnumType.CREDIT_CARD
      ? 'credit-card-modal'
      : ''
  }`;

  return (
    <>
      <div className={modalClass}>
        <div className="black-background" onClick={closeModal}></div>
        <div className={modalWindowClass}>
          <button className="close-button" onClick={closeModal}>
            &times;
          </button>
          {renderModalContent()}
        </div>
      </div>
    </>
  );
};

export default ShowModal;
