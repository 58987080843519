import React, { useState, useEffect } from 'react';
import './credit-card-form.css';

type CreditCardFormPropsType = {
  disabled: boolean;
  onSubmit: (event: React.FormEvent) => void;
  isLoading: boolean;
  setIsFormValid: (isValid: boolean) => void;
};

const CreditCardForm = ({
  disabled,
  onSubmit,
  isLoading,
  setIsFormValid,
}: CreditCardFormPropsType) => {
  const [name, setName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expMonth, setExpMonth] = useState('');
  const [expYear, setExpYear] = useState('');
  const [cvv, setCvv] = useState('');

  useEffect(() => {
    const isValid = !!name && !!cardNumber && !!expMonth && !!expYear && !!cvv;
    setIsFormValid(isValid);
  }, [name, cardNumber, expMonth, expYear, cvv, setIsFormValid]);

  return (
    <form className="credit-card" onSubmit={onSubmit} autoComplete="off">
      <div className="form-header">
        <h4 className="title">Информация о карте</h4>
      </div>

      <div className="form-body">
        <input
          type="text"
          className="card-number"
          placeholder="Имя"
          data-cp="name"
          required
          disabled={disabled}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          className="card-number"
          placeholder="Номер карты"
          data-cp="cardNumber"
          required
          disabled={disabled}
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
        />

        <div className="date-field">
          <div className="month">
            <select
              data-cp="expDateMonth"
              required
              disabled={disabled}
              value={expMonth}
              onChange={(e) => setExpMonth(e.target.value)}
            >
              <option value="">Месяц</option>
              <option value="1">Январь</option>
              <option value="2">Февраль</option>
              <option value="3">Март</option>
              <option value="4">Апрель</option>
              <option value="5">Май</option>
              <option value="6">Июнь</option>
              <option value="7">Июль</option>
              <option value="8">Август</option>
              <option value="9">Сентябрь</option>
              <option value="10">Октябрь</option>
              <option value="11">Ноябрь</option>
              <option value="12">Декабрь</option>
            </select>
          </div>
          <div className="year">
            <select
              data-cp="expDateYear"
              required
              disabled={disabled}
              value={expYear}
              onChange={(e) => setExpYear(e.target.value)}
            >
              <option value="">Год</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
            </select>
          </div>
        </div>

        <div className="card-verification">
          <div className="cvv-input">
            <input
              type="number"
              placeholder="CVV"
              data-cp="cvv"
              required
              maxLength={3}
              disabled={disabled}
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
          </div>
          <div className="cvv-details">
            <p>3 или 4 цифры обычно указаны на полосе для подписи</p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreditCardForm;
