import "./chats-list.css"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const ChatsList = () => {
  return (
    <div className='chats-list'>
        <div className="chats-list-container">
            <SearchOutlinedIcon fontSize="large"/>
            <p>Нет актуальных чатов</p>
        </div>
    </div>
  )
}

export default ChatsList