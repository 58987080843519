import { useEffect } from 'react';
import CartCategories from '../components/cart-categories/CartCategories';
import CartContainer from '../components/cart-container/CartContainer';
import Header from '../components/header/Header';
import ShowModal from '../components/show-modal/ShowModal';
import { useNavigate } from 'react-router-dom';
import {
  getActiveOrdersTC,
  getCartItemsTC,
  getCompletedOrdersTC,
} from '../state/cart-reducer';
import { useDispatch } from 'react-redux';

const ShoppingCartPage = () => {
  return (
    <div>
      <div className="sticky-container">
        <Header />
        <CartCategories />
      </div>
      <CartContainer />
      <ShowModal />
    </div>
  );
};

export default ShoppingCartPage;
