import { useEffect, useState } from 'react';
import './app.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesTC } from './state/category-reducer';
import { HashRouter, Route, Routes } from 'react-router-dom'; // Use HashRouter instead of BrowserRouter
import { getProductsTC } from './state/product-reducer';
import { getSubcategoriesTC } from './state/subcategory-reducer';
import MainPage from './pages/MainPage';
import ChatsPage from './pages/ChatsPage';
import ShopsPage from './pages/ShopsPage';
import SearchPage from './pages/SearchPage';
import ProfilePage from './pages/ProfilePage';
import {
  getFavouriteProducts,
  getSavedCardsTC,
  getUserAddressesTC,
  getUserInfoTC,
  setAlertAC,
} from './state/user-reducer';
import ShoppingCartPage from './pages/ShoppingCartPage';
import ActiveOrdersPage from './pages/ActiveOrdersPage';
import CompletedOrdersPage from './pages/CompletedOrdersPage';
import {
  getActiveOrdersTC,
  getCartItemsTC,
  getCompletedOrdersTC,
} from './state/cart-reducer';
import ProductDetailPage from './pages/ProductDetailPage';
import {
  getShopsTC,
  getShopSubtypesTC,
  getShopTypesTC,
} from './state/shops-reducer';
import ChangeProfilePage from './pages/ChangeProfilePage';
import SupportPage from './pages/SupportPage';
import FavouritesPage from './pages/FavouritesPage';
import AddressesPage from './pages/AddressesPage';
import { RootStateType } from './state/store';
import { Alert, Fade } from '@mui/material';

const App = () => {
  const dispatch = useDispatch<any>();
  const alert = useSelector((state: RootStateType) => state.userData.alert);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        dispatch(getUserInfoTC()),
        dispatch(getCategoriesTC()),
        dispatch(getProductsTC(1)),
        dispatch(getSubcategoriesTC()),
        dispatch(getUserAddressesTC()),
        dispatch(getSavedCardsTC()),
        dispatch(getCartItemsTC()),
        dispatch(getActiveOrdersTC()),
        dispatch(getCompletedOrdersTC()),
        dispatch(getShopsTC()),
        dispatch(getShopTypesTC()),
        dispatch(getShopSubtypesTC(1)),
        dispatch(getFavouriteProducts()),
      ]);
    };

    fetchAllData();
  }, [dispatch]);

  useEffect(() => {
    if (alert.message) {
      setVisible(true);

      const timer = setTimeout(() => {
        setVisible(false);
        dispatch(setAlertAC(null, undefined));
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert, dispatch]);

  return (
    <div className="app">
      {visible && (
        <Alert
          className="alert"
          severity={alert.severity}
          onClose={() => dispatch(setAlertAC(null, undefined))}
        >
          {alert.message}
        </Alert>
      )}
      <HashRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/product-detail" element={<ProductDetailPage />} />
          <Route
            path="/product-detail/:productId"
            element={<ProductDetailPage />}
          />
          <Route path="/profile" element={<ProfilePage />} />
          <Route
            path="/profile/change-profile"
            element={<ChangeProfilePage />}
          />
          <Route path="/chats" element={<ChatsPage />} />
          <Route path="/shops" element={<ShopsPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/favourites" element={<FavouritesPage />} />
          <Route path="/addresses" element={<AddressesPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/shopping-cart" element={<ShoppingCartPage />} />
          <Route path="/active-orders" element={<ActiveOrdersPage />} />
          <Route
            path="/active-orders/:orderId"
            element={<ActiveOrdersPage />}
          />
          <Route path="/completed-orders" element={<CompletedOrdersPage />} />
          <Route
            path="/shopping-cart/:orderId"
            element={<ShoppingCartPage />}
          />
        </Routes>
      </HashRouter>
    </div>
  );
};

export default App;
