import { useEffect, useState } from 'react';
import './change-profile.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import {
  changeUserInfoTC,
  getUserInfoTC,
  UserType,
} from '../../state/user-reducer';
import GoBackButton from '../../elements/go-back-button/GoBackButton';

const ChangeProfile = () => {
  const dispatch = useDispatch<any>();
  const userInfo = useSelector<RootStateType, UserType>(
    (state) => state.userData.userInfo
  );
  const [profileImg, setProfileImg] = useState<string>(
    userInfo?.profile_picture || '/static/images/no-profile.png'
  );
  const [userName, setUserName] = useState<string>(userInfo?.name || '');
  const [userEmail, setUserEmail] = useState<string>(userInfo?.email || '');
  const [cityId, setCityId] = useState<number>(userInfo?.city || 1);
  const [error, setError] = useState<string | null>(null); // Error handling state

  const handleChangeImg = (e: any) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      setProfileImg(reader.result as string);
    }
  };
  console.log("image file", profileImg)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Create FormData object to handle file upload
    const formData = new FormData();
    formData.append('name', userName);
    formData.append('email', userEmail);
    formData.append('city', cityId.toString());

    dispatch(changeUserInfoTC(formData));
  };

  return (
    <div className="">
      <GoBackButton />
      <div className="add-category">
        <p>Профиль</p>
        <div className="add-category-photo">
          <input
            type="file"
            id="photo-upload"
            accept="image/png, image/jpeg"
            onChange={handleChangeImg}
            style={{ display: 'none' }}
            disabled
          />
          <label htmlFor="photo-upload">
            <img
              src={profileImg}
              alt="Category"
              className="category-img-preview"
            />
          </label>
        </div>

        <form action="" className="add-category-form" onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          <input
            type="text"
            placeholder="ФИО"
            value={userName}
            onChange={(e: any) => setUserName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="E-mail"
            value={userEmail}
            onChange={(e: any) => setUserEmail(e.target.value)}
          />
          <select
            name=""
            id=""
            value={cityId}
            onChange={(e) => setCityId(Number(e.target.value))}
          >
            <option value={1}>Алматы</option>
            <option value={2}>Астана</option>
          </select>
          <button type="submit">Изменить</button>
        </form>
      </div>
    </div>
  );
};

export default ChangeProfile;
