import React from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { CartItemType } from '../../state/cart-reducer';
import { useParams } from 'react-router-dom';
import ActiveOrder from '../active-order/ActiveOrder';
import OrderUnit from '../order-unit/OrderUnit';
import { mainURL } from '../../config';

type TotalCostModalPropsType = {
  onConfirm: () => void;
};

const TotalCostModal = ({ onConfirm }: TotalCostModalPropsType) => {
  const { orderId } = useParams();
  const cartItems = useSelector<RootStateType, CartItemType[]>(
    (state) => state.cartItems.itemsInCart
  );

  // Ищем заказ по orderId
  const cartItem = cartItems.find((item) => item.id === Number(orderId));

  return (
    <div className="total-cost-modal">
      <p className="total-cost-title">Подтверждение суммы</p>

      <div className="total-order-units-list">
        <p className='title'>Продукты</p>
        {cartItem?.order_units.map((unit) => {
          return (
            <div key={unit.id} className="order-unit">
              <img
                src={
                  mainURL + unit.unit.images[0]?.image || '/fallback-image-url'
                }
                alt=""
                className="order-unit-img"
              />
              <div className="order-unit-info">
                <span className="unit-name">{unit.unit.name}</span>
              </div>
              <div className="unit-price">
                {unit.unit.price} ₸ <span> x {unit.quantity} шт.</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="total-paycheck-content">
        <p className="title">Цена</p>

        <div className="delivery-cost-paycheck">
          <p>Доставка</p>
          <p>{cartItem?.delivery_price} ⍑</p>
        </div>
        <div className="units-cost-paycheck">
          <p>Заказы</p>
          <p>{cartItem?.order_total_price} ⍑</p>
        </div>

        <div className="total-paycheck">
          <p>Общая сумма</p>
          <p>{(cartItem?.order_total_price || 0) + (cartItem?.delivery_price || 0)} ⍑</p>
        </div>
      </div>
      
      <button className="proceed-btn" onClick={onConfirm}>
        Подтвердить и продолжить к оплате
      </button>
    </div>
  );
};

export default TotalCostModal;
