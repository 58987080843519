import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { getShopSubtypesTC, ShopTypeType } from '../../state/shops-reducer';
import CategoryItem from '../category-item/CategoryItem';
import { CategoryType } from '../../state/category-reducer';

const ShopSubtypesList = () => {
    const dispatch = useDispatch<any>();
  const shopSubtypesList = useSelector<RootStateType, ShopTypeType[]>(
    (state) => state.shopData.shopSubtypes
  );

  const handleChangeList = (category: CategoryType) => {
  }; 

  return (
    <div className="categories">
      <div className="category-list">
        {shopSubtypesList.length > 0 ? (
          shopSubtypesList.map((type) => (
            <CategoryItem category={type} key={type.id} handleChangeList={handleChangeList}/>
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default ShopSubtypesList;
