import { useSelector } from "react-redux"
import "./favourites-list.css"
import { RootStateType } from "../../state/store"
import { ProductType } from "../../state/product-reducer"
import { Navigate, useNavigate } from "react-router-dom"
import { mainURL } from "../../config"

const FavouritesList = () => {
  const navigate = useNavigate();
  const favourites = useSelector<RootStateType, ProductType[]>(state => state.userData.favouriteProducts);

  const handleOpenProductDetail = (id: number) => {
    navigate('/product-detail/' + id);
  };

  return (
    <div className="favourites-list">
      {
        favourites.map((favourite) => {
          return <div
          key={favourite.id}
          className="search-result"
          onClick={() => handleOpenProductDetail(favourite.id)}
        >
          <img src={mainURL + favourite.image} alt="" />
          <div className="search-result-info">
            <p className="product-name">{favourite.name}</p>
          </div>
          <div className="search-result-cost">
            <p className="item-cost">{favourite.price} ₸</p>
          </div>
        </div>
        })
      }
    </div>
  )
}

export default FavouritesList