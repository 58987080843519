import "./order-unit.css"
import { mainURL } from '../../config';
import { CartItemType } from '../../state/cart-reducer';

type OrderUnitProps = {
  unit: CartItemType['order_units'][0];
  handleIncreaseUnitAmount: (unitId: number) => void;
  handleDecreaseUnitAmount: (unitId: number, unitQuantity: number) => void;
};

const OrderUnit = ({
  unit,
  handleIncreaseUnitAmount,
  handleDecreaseUnitAmount,
}: OrderUnitProps) => (
  <div key={unit.id} className="order-unit">
    <img
      src={mainURL + unit.unit.images[0]?.image || '/fallback-image-url'}
      alt=""
      className="order-unit-img"
    />
    <div className="order-unit-info">
      <span className="unit-name">{unit.unit.name}</span>
      <div className="quantity-controls">
        <button onClick={() => handleDecreaseUnitAmount(unit.id, unit.quantity)}>
          -
        </button>
        <span>{unit.quantity}</span>
        <button onClick={() => handleIncreaseUnitAmount(unit.id)}>+</button>
      </div>
    </div>
    <div className="unit-price">
      {unit.unit.price} ₸ <span> x 1 шт.</span>
    </div>
  </div>
);

export default OrderUnit;