import ShopSubtypesList from '../components/category-list/ShopSubtypesList';
import ShopTypesList from '../components/category-list/ShopTypesList';
import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import ShopsList from '../components/shops-list/ShopsList';
import ShowModal from '../components/show-modal/ShowModal';

const ShopsPage = () => {
  return (
    <div>
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>
      <ShopTypesList />
      <ShopSubtypesList />

      <div className="main-container">
        <ShopsList />
        <ShowModal />
      </div>
    </div>
  );
};

export default ShopsPage;
