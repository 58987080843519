import { useDispatch, useSelector } from 'react-redux';
import './category-list.css';
import { RootStateType } from '../../state/store';
import {
  CategoryStateType,
  CategoryType,
  setActiveCategoryAC,
} from '../../state/category-reducer';
import CategoryItem from '../category-item/CategoryItem';
import { setActiveSubcategoryAC } from '../../state/subcategory-reducer';

type CategoryListPropsType = {};

const CategoryList = (props: CategoryListPropsType) => {
  const dispatch = useDispatch<any>();
  const { categories: categoryList, activeCategory } = useSelector<
    RootStateType,
    CategoryStateType
  >((state) => state.categories);

  const handleChangeList = (category: CategoryType) => {
    dispatch(setActiveCategoryAC(category));
    dispatch(setActiveSubcategoryAC(null));
  };

  return (
    <div className="categories">
      <div className="category-list">
        {categoryList.length > 0 ? (
          categoryList.map((category) => (
            <CategoryItem
              handleChangeList={handleChangeList}
              category={category}
              key={category.id}
            />
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
