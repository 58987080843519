import { useSelector } from 'react-redux';
import './search-results.css';
import { RootStateType } from '../../state/store';
import { ProductType } from '../../state/product-reducer';
import { mainURL } from '../../config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchResults = () => {
  const navigate = useNavigate();
  const isLoading = useSelector<RootStateType, boolean>(
    (state) => state.products.loading
  );

  const searchResults = useSelector<RootStateType, ProductType[]>(
    (state) => state.products.searchResults
  );

  const handleOpenProductDetail = (id: number) => {
    navigate('/product-detail/' + id);
  };

  return (
    <div className="search-results">
      {!isLoading ? (
        searchResults.map((product) => (
          <div
            key={product.id}
            className="search-result"
            onClick={() => handleOpenProductDetail(product.id)}
          >
            <img src={mainURL + product.image} alt="" />
            <div className="search-result-info">
              <p className="product-name">{product.name}</p>
            </div>
            <div className="search-result-cost">
              <p className="item-cost">{product.price} ₸</p>
            </div>
          </div>
        ))
      ) : (
        <p className="loading">Loading...</p>
      )}
    </div>
  );
};

export default SearchResults;
