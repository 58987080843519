import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootStateType } from '../../state/store';
import { CartItemType } from '../../state/cart-reducer';
import { mainURL } from '../../config';
import PhoneIcon from '@mui/icons-material/Phone';
import ModeCommentIcon from '@mui/icons-material/ModeComment';

const OrderTrackingModal = () => {
  const { orderId } = useParams();
  const activeOrders = useSelector<RootStateType, CartItemType[]>(
    (state) => state.cartItems.activeOrders
  );

  // Ищем заказ по orderId
  const activeOrder = activeOrders.find((item) => item.id === Number(orderId));
  return (
    <div className="total-cost-modal">
      <p className="order-tracking-title">Трекинг заказа</p>

      <div
        className={`order-tracking-step ${
          activeOrder?.status === 0 || activeOrder?.status === 1 ? 'active' : ''
        }`}
      >
        <img src="/static/images/0001.png" alt="" />
        <p>Мы собираем ваш заказ!</p>
      </div>
      <div
        className={`order-tracking-step ${
          activeOrder?.status === 2 || activeOrder?.status === 3 ? 'active' : ''
        }`}
      >
        <img src="/static/images/0002.png" alt="" />
        <p>Доставка в процессе!</p>
      </div>
      <div
        className={`order-tracking-step ${
          activeOrder?.status === 4 || activeOrder?.status === 5 ? 'active' : ''
        }`}
      >
        <img src="/static/images/0003.png" alt="" />
        <p>Заказ доставлен!</p>
      </div>
      <p className="tracking title">
        {activeOrder?.self_pickup ? 'Самовывоз' : 'Доставка'}
      </p>
      <div className="order-tracking-delivery-address">
        {activeOrder?.self_pickup ? (
          <>
            <img src="/static/images/shop.png" alt="" />
            <p>
              {activeOrder?.delivery_address.address_string}{' '}
              {activeOrder.delivery_address.detail_info}
            </p>
          </>
        ) : (
          <>
            <img src="/static/images/home.png" alt="" />
            <p>{activeOrder?.shop.address}</p>
          </>
        )}
      </div>

      <div className="order-tracking-shop-info">
        <p className="tracking title">Shop</p>
        <div className="shop-info-container">
          <div className="tracking-shop-name">
            <img src={mainURL + activeOrder?.shop.logo} alt="" />
            <p>{activeOrder?.shop.name}</p>
          </div>
          <div className="icons">
            <a href={`tel:${activeOrder?.shop.phone_numbers[0]}`}>
              <PhoneIcon />
            </a>
            <a >
              <ModeCommentIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="total-order-tracking-content">
        <p className="tracking title">Краткая информация</p>

        <div className="delivery-cost-paycheck">
          <p>Доставка</p>
          <p>{activeOrder?.delivery_price} ⍑</p>
        </div>
        <div className="units-cost-paycheck">
          <p>Заказы</p>
          <p>{activeOrder?.order_total_price} ⍑</p>
        </div>

        <div className="total-paycheck">
          <p>Общая сумма</p>
          <p>
            {(activeOrder?.order_total_price || 0) +
              (activeOrder?.delivery_price || 0)}{' '}
            ⍑
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderTrackingModal;
