import { Dispatch } from 'redux';
import axios from 'axios';
import { mainURL } from '../config';

export type BrandType = {
  id: number;
  name: string;
};

type ProductCategoryType = {
  id: number;
  name: string;
  image: string;
  parent_category_info: {
    id: number;
    name: string;
  } | null;
};

export type VerbosePropertyType = {
  name: string;
  property_id: number;
  property_value_id: number;
  value: string;
};

export type UnitType = {
  id: number;
  name: string;
  old_price: number;
  price: number;
  images: Array<{ id: number; image: string }>;
  product_id: number;
  description: string;
  num_in_stock: number;
  verbose_properties: VerbosePropertyType[];
};

export type ProductType = {
  id: number;
  name: string;
  price: number;
  description: string;
  is_active: boolean;
  image: string;
  units: UnitType[];
  categories: ProductCategoryType[];
};

export type ProductStateType = {
  searchResults: ProductType[];
  products: ProductType[];
  loading: boolean;
};

export const initialState: ProductStateType = {
  products: [],
  searchResults: [],
  loading: false,
};

export type SetProductsActionType = {
  type: 'SET_PRODUCTS';
  products: ProductType[];
};

export type SetProductsBySubcategoryActionType = {
  type: 'SET_PRODUCTS_BY_SUBCATEGORY';
  products: ProductType[];
};

export type SetSearchResultsActionType = {
  type: 'SET_SEARCH_RESULTS';
  searchResults: ProductType[];
};

export type SetLoadingActionType = {
  type: 'SET_LOADING';
  loading: boolean;
};

export type ClearSearchResultsActionType = {
  type: 'CLEAR_SEARCH_RESULTS';
};

export type ProductActionType =
  | SetProductsActionType
  | SetProductsBySubcategoryActionType
  | SetSearchResultsActionType
  | SetLoadingActionType
  | ClearSearchResultsActionType;

  export const ProductReducer = (
    state: ProductStateType = initialState,
    action: ProductActionType
  ) => {
    switch (action.type) {
      case 'SET_PRODUCTS':
        return { ...state, products: [...state.products, ...action.products], loading: false };
      case 'SET_PRODUCTS_BY_SUBCATEGORY':
        return { ...state, products: [...state.products, ...action.products], loading: false };
      case 'SET_SEARCH_RESULTS':
        return { ...state, searchResults: action.searchResults, loading: false };
        case 'CLEAR_SEARCH_RESULTS':
          return { ...state, searchResults: [] };
      case 'SET_LOADING':
        return { ...state, loading: action.loading }; // New case to handle loading
      default:
        return state;
    }
  };

export const setProductsAC = (
  products: ProductType[]
): SetProductsActionType => {
  return {
    type: 'SET_PRODUCTS',
    products,
  };
};

// export const setProductsBySubcategoryAC = (
//   products: ProductType[]
// ): SetProductsBySubcategoryActionType => {
//   return {
//     type: 'SET_PRODUCTS_BY_SUBCATEGORY',
//     products,
//   };
// };

export const setSearchResultsAC = (
  searchResults: ProductType[]
): SetSearchResultsActionType => {
  return {
    type: 'SET_SEARCH_RESULTS',
    searchResults,
  };
};

export const setLoadingAC = (loading: boolean): SetLoadingActionType => ({
  type: 'SET_LOADING',
  loading,
});

export const clearSearchResultsAC = (): ClearSearchResultsActionType => ({
  type: 'CLEAR_SEARCH_RESULTS',
});

export const getProductsTC = (page: number) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(setLoadingAC(true));
    try {
      const response = await axios.get(
        mainURL + `/en/api/v1/shops/products/?page=${page}`
      );
      const { results } = response.data;

      const newProducts = results
        .map((product: any) => mapProductFromAPI(product))
        .filter(
          (product: ProductType | null): product is ProductType =>
            product !== null
        );

      const currentProducts: ProductType[] = getState().products.products;

      const uniqueProducts = newProducts.filter((newProduct: ProductType) => {
        return !currentProducts.some(
          (currentProduct) => currentProduct.id === newProduct.id
        );
      });

      if (uniqueProducts.length > 0) {
        dispatch(setProductsAC(uniqueProducts));
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    } 
  };
};

export const mapProductFromAPI = (data: any) => {
  if (data && data.units && data.units.length > 0) {
    // Filter units to include only those with num_in_stock > 0
    const availableUnits = data.units.filter(
      (unit: UnitType) => unit.num_in_stock > 0
    );

    // If no units with stock, return null
    if (availableUnits.length === 0) return null;

    // Return mapped product with available units
    return {
      id: data.id,
      name: data.name,
      price: availableUnits[0].price, // Using the first available unit's price
      description: availableUnits[0].description || data.description,
      is_active: data.is_active,
      image: availableUnits[0].images[0]?.image || '',
      units: availableUnits, // Only units with stock
      categories: data.categories,
    };
  }
  return null;
};

export const getSearchResults = (name: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(setLoadingAC(true));
    dispatch(clearSearchResultsAC()); // Clear previous search results

    try {
      const response = await axios.get(
        mainURL + `/en/api/v1/shops/search/?name=${name}`
      );

      const products = await Promise.all(
        response.data.results.map(async (result: any) => {
          const productDetail = await getProductDetail(result.id);
          return mapProductFromAPI(productDetail);
        })
      );

      const validProducts = products.filter(
        (product): product is ProductType => product !== null
      );

      dispatch(setSearchResultsAC(validProducts));
    } catch (error) {
      console.error('Unexpected error:', error);
    } finally {
      dispatch(setLoadingAC(false));
    }
  };
};


export const getProductDetail = async (id: number) => {
  try {
    const response = await axios.get(
      mainURL + `/en/api/v1/shops/products/${id}/`
    );


    return response.data;
  } catch (error) {
    console.error('Unexpected error:', error);
    return null;
  }
};
