import { useState } from 'react';
import { DeliveryAddress } from '../../state/cart-reducer';
import CartMap from '../map/CartMap';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { Alert } from '@mui/material';
import {
  addUserAddressTC,
  changeDefaultAddressTC,
} from '../../state/user-reducer';

type AddressInputFormProps = {};

export const AddressInputForm = ({}: AddressInputFormProps) => {
  const dispatch = useDispatch<any>();
  const [addressFromInput, setAddressFromInput] = useState('');
  const [isError, setIsError] = useState<string | null>(null);
  const [showAddressInput, setShowAddressInput] = useState(false);
  const [newLatitude, setNewLatitude] = useState<number | null>(null);
  const [newLongitude, setNewLongitude] = useState<number | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [addressIsAdded, setAddressIsAdded] = useState(false);
  // Состояние для позиции маркера, инициализируется либо дефолтным адресом, либо координатами по умолчанию

  const userAddresses = useSelector<RootStateType, DeliveryAddress[]>(
    (state) => state.userData.userAddresses
  );

  const defaultAddress = userAddresses.find(
    (address) => address.is_default === true
  );
  const [markerPosition, setMarkerPosition] = useState<[number, number]>(
    defaultAddress
      ? [defaultAddress.lng, defaultAddress.lat]
      : [76.8638976, 43.237376] // Координаты по умолчанию
  );

  const handleAddNewAddress = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return console.error('No access token found.');

    if (!addressFromInput.trim()) {
      return setIsError('Пожалуйста, выберите и введите корректный адрес.');
    } else if (!selectedAddress || !newLatitude || !newLongitude) {
      return setIsError('Пожалуйста, укажите место на карте.');
    }

    try {
      // Call the function to add a new address
      await dispatch(
        addUserAddressTC(
          selectedAddress,
          addressFromInput,
          newLatitude,
          newLongitude
        )
      );

      // Update the state after successful address addition
      setSelectedAddress(null);
      setIsError(null);
      setAddressFromInput('');
      setNewLatitude(null);
      setNewLongitude(null);
      setShowAddressInput(false);
      setAddressIsAdded(true); // Show the success alert

      // Hide the alert after a few seconds
      setTimeout(() => {
        setAddressIsAdded(false);
      }, 3000);
    } catch (error) {
      console.error('Error adding address:', error);
      setIsError('Произошла ошибка при добавлении адреса.');
    }
  };

  const handleChangeDefaultAddress = (address: DeliveryAddress) => {
    setNewLatitude(address.lat);
    setNewLongitude(address.lng);
    setSelectedAddress(address.address_string);
    dispatch(changeDefaultAddressTC(address.id));
    setMarkerPosition([address.lng, address.lat]);
  };

  return (
    <div className="user-saved-addresses-container">
      <p className="delivery-address-title">Адреса доставки:</p>
      <div className="user-address-list">
        {userAddresses.length > 0 ? (
          userAddresses.map((address) => (
            <label key={address.id} className="user-address-label">
              <input
                name="address"
                type="radio"
                value={address.address_string}
                checked={address.is_default}
                onChange={() => handleChangeDefaultAddress(address)}
              />
              {address.address_string}
            </label>
          ))
        ) : (
          <p className="no-delivery-address-title">Нет доступных адресов.</p>
        )}
      </div>

      {!showAddressInput && (
        <button
          className="cart-item-add-address-button"
          onClick={() => setShowAddressInput(!showAddressInput)}
        >
          Добавить новый адрес
        </button>
      )}

      {showAddressInput && (
        <>
          <CartMap
            selectedAddress={selectedAddress}
            defaultAddress={defaultAddress ?? null}
            setSelectedAddress={setSelectedAddress}
            setNewLatitude={setNewLatitude}
            setNewLongitude={setNewLongitude}
            setIsError={setIsError}
            newLatitude={newLatitude}
            newLongitude={newLongitude}
            markerPosition={markerPosition}
            setMarkerPosition={setMarkerPosition}
          />

          <div className="manual-address-input">
            <div className="address-input-container">
              <input
                onChange={(e) => {
                  setIsError(null);
                  setAddressFromInput(e.target.value);
                }}
                className={isError ? 'address-input error' : 'address-input'}
                type="text"
                placeholder="Укажите номер дома и квартиры"
                value={addressFromInput}
              />
              {isError && <p className="error-text">{isError}</p>}
            </div>
            <button
              onClick={handleAddNewAddress}
              className="cart-item-add-address-button"
            >
              Добавить адрес
            </button>
          </div>
          {addressIsAdded && (
            <Alert
              severity="success"
              onClose={() => setAddressIsAdded(false)}
              className="custom-alert"
            >
              Адрес успешно добавлен!
            </Alert>
          )}
        </>
      )}
    </div>
  );
};

export default AddressInputForm;
