import { useSelector } from 'react-redux';
import './cart-container.css';
import { CartItemType } from '../../state/cart-reducer';
import CartItem from '../cart-item/CartItem';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { RootStateType } from '../../state/store';


type CartContainerPropsType = {
};

const CartContainer = () => {
  
  // Fetch items in the cart from state
  const cartItems = useSelector<RootStateType, CartItemType[]>(
    (state) => state.cartItems.itemsInCart || []
  );
  
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);

  // Toggle expand/collapse state for a specific item
  const handleToggleUnits = (itemId: number) => {
    setExpandedItemId((prev) => (prev === itemId ? null : itemId));
  };


   // Set the first item as expanded by default when the component mounts
   useEffect(() => {
    if (cartItems.length > 0) {
      setExpandedItemId(cartItems[0].id); // Set the first item's ID as expanded by default
    }
  }, []);

  return (
    cartItems.length > 0 ? <div className="cart-container">
        <div className="main-container">
          <p className="cart-title">Корзина</p>

          <div className="cart-container-info">
            <div className="cart-items">
              <div className="cart-items-list">
                {cartItems.map((item) => (
                  <CartItem
                    key={item.id}
                    cartItem={item}
                    isAnyExpanded={expandedItemId !== null}
                    isExpanded={expandedItemId === item.id}
                    onToggleUnits={() => handleToggleUnits(item.id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
    </div>
    : 
    <div className='empty-orders'>
      <ProductionQuantityLimitsIcon />
      <p>Корзина пуста</p>
    </div>
  );
};

export default CartContainer;
