import './header.css';
import { useAuth } from '../hoc/useAuth';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { useNavigate } from 'react-router-dom';
import { changeModalTypeAC, modalEnumType } from '../../state/user-reducer';

type HeaderPropsType = {};

const Header = (props: HeaderPropsType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  // Селекторы для получения данных из хранилища
  const cartItemsCount = useSelector<RootStateType, number>(
    (state) => state.cartItems.itemsInCart.length
  );

  // Обработчик смены типа модального окна
  const handleModalChange = (modalType: modalEnumType) => {
    dispatch(changeModalTypeAC(modalType));
  };

  // Отрисовка кнопок для авторизованного пользователя
  const renderAuthenticatedButtons = () => (
    <>
      <div
        className="shopping-cart-logo"
        onClick={() => navigate('/shopping-cart')}
      >
        <ShoppingCartOutlinedIcon className="cart-icon" fontSize="large" />
        <div className="shopping-cart-amount">
          <p>{cartItemsCount}</p>
        </div>
      </div>
      <button className="logout" onClick={logout}>
        Выйти
      </button>
    </>
  );

  // Отрисовка кнопки для неавторизованного пользователя
  const renderUnauthenticatedButton = () => (
    <button className="login" onClick={() => handleModalChange(modalEnumType.LOGIN)}>
      Войти
    </button>
  );

  return (
    <header className="header">
      <div className="header-content">
        <img
          src="/static/images/stubla_logo.png"
          alt="logo"
          className="main-logo"
          onClick={() => navigate('/')}
        />

        <div className="log-buttons">
          {isAuthenticated ? renderAuthenticatedButtons() : renderUnauthenticatedButton()}
        </div>
      </div>
    </header>
  );
};

export default Header;
