import { useState } from 'react';
import GoBackButton from '../../elements/go-back-button/GoBackButton';
import './support.css';
import { useDispatch } from 'react-redux';
import { sendFeedback } from '../../utils/fetch';

enum SupportEnumType {
  SUPPORT = 'support',
  COMPLAINT = 'complaint',
}

const Support = () => {
  const dispatch = useDispatch<any>();
  const [supportType, setSupportType] = useState<SupportEnumType>(
    SupportEnumType.SUPPORT
  );
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Send form data to server
    if(message.trim().length > 0) {
      sendFeedback(message);
      setMessage("");
    }
  };

  const handleSupportTypeChange = (type: SupportEnumType) => {
    setSupportType(type);
  };
  return (
    <div className="support">
      <div className="support-content">
        <GoBackButton />
        <div className="support-types">
          <button
            className={supportType === SupportEnumType.SUPPORT ? 'active' : ''}
            onClick={() => handleSupportTypeChange(SupportEnumType.SUPPORT)}
          >
            Предложение
          </button>
          <button
            className={
              supportType === SupportEnumType.COMPLAINT ? 'active' : ''
            }
            onClick={() => handleSupportTypeChange(SupportEnumType.COMPLAINT)}
          >
            Жалоба
          </button>
        </div>
        <form className="support-form" onSubmit={handleSubmit}>
          <textarea
            placeholder="Введите текст..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <p className="address">
            «Служба поддержки» <br /> Stubla, Алматы, Самал-2, 58 <br />{' '}
            10:00-21:00
          </p>
          <p className="phone-number">
            <a href={`tel:+77008888855`}>Позвонить +7 (700) 888-88-55</a>
          </p>
          <button type="submit">Отправить</button>
        </form>
      </div>
    </div>
  );
};

export default Support;
