import React from 'react';
import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import ShowModal from '../components/show-modal/ShowModal';
import Support from '../components/support/Support';
import GoBackButton from '../elements/go-back-button/GoBackButton';

const SupportPage = () => {
  return (
    <div className='page support-page'>
      <GoBackButton />
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <div className="main-container">
        <Support />
        <ShowModal />
      </div>
    </div>
  );
};

export default SupportPage;
