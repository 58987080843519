import { useEffect } from 'react';
import CategoryList from '../components/category-list/CategoryList';
import ProductList from '../components/product-list/ProductList';
import ShowModal from '../components/show-modal/ShowModal';
import Header from '../components/header/Header';
import SubcategoryList from '../components/category-list/SubcategoryList';
import Navbar from '../components/navbar/Navbar';

const MainPage = () => {
  // Используем useEffect для выполнения побочных эффектов (например, для отладки или очистки данных)
  useEffect(() => {
    // localStorage.removeItem('accessToken');
  }, []);

  return (
    <div className="page main-page">
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>
      <CategoryList />
      <SubcategoryList />
      <div className="main-container">
        <ProductList />
        <ShowModal />
      </div>
    </div>
  );
};

export default MainPage;
