import { useNavigate } from 'react-router-dom';
import './go-back-button.css';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const GoBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  
  return (
    <div className="go-back-button" onClick={handleGoBack}>
      <ArrowBackOutlinedIcon fontSize="large" />
    </div>
  );
};

export default GoBackButton;
