import React, { useEffect, useState } from 'react';
import { loginByCode, verifyPhoneNumber } from '../../utils/fetch';

type LoginModalPropsType = {
};

const LoginModal = (props: LoginModalPropsType) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isError, setIsError] = useState<null | string>(null);
  const [showConfirmationInput, setShowConfirmationInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  const handleSetPhoneNumber = (e: any) => {
    setIsError(null);
    const input = e.target.value;

    const numericInput = input.slice(2).replace(/\D/g, '');
    setPhoneNumber(numericInput);
  };

  const handleSetConfirmationCode = (e: any) => {
    setIsError(null);
    setConfirmationCode(e.target.value);
  };

  const handleCheckPhoneNumber = async (e: any) => {
    e.preventDefault();
    setIsLoading(true); 
    try {
      const response = await verifyPhoneNumber(`+7${phoneNumber}`);

      if (response.message === 'ok') {
        setIsError(null);
        setShowConfirmationInput(true);
      }
    } catch (err) {
      setIsError('Invalid phone number');
      console.error('Error:', err);
    }finally {
      setIsLoading(false); // Завершаем загрузку
    }
  };

  const handleLoginByCode = async (e: any) => {
    e.preventDefault();
    setIsLoading(true); 

    try {
      const response = await loginByCode(`+7${phoneNumber}`, confirmationCode);

      if (response) {
        
        window.location.reload();
      }
    } catch (err) {
      setIsError('Failed to login with provided code');
      console.error('Error:', err);
    }finally {
      setIsLoading(false); // Завершаем загрузку
    }
  };

  return (
    <div className="modal-info">
      <p className="modal-title">Логин</p>
      <form
        className="modal-form"
        onSubmit={
          showConfirmationInput ? handleLoginByCode : handleCheckPhoneNumber
        }
      >
        <input
          type="text"
          placeholder="Номер телефона"
          value={`+7${phoneNumber}`}
          onChange={handleSetPhoneNumber}
          className={
            isError && !showConfirmationInput ? 'input-error' : 'input'
          }
          maxLength={12}
        />

        {showConfirmationInput && (
          <input
            type="text"
            placeholder="Код подтверждения"
            value={confirmationCode}
            onChange={handleSetConfirmationCode}
            className={isError ? 'input-error' : 'input'}
            maxLength={6}
          />
        )}
        {isError && <p className="error-text">{isError}</p>}
        {/* <input type="password" placeholder="Password" />
              <p className="forgot-password-text">Forgot Password?</p> */}
        <div className="modal-buttons">
        <button
            type="submit"
            className="login-button"
            disabled={isLoading || (!phoneNumber && !confirmationCode)} // Кнопка неактивна при загрузке или пустых данных
          >
            {isLoading ? 'Загрузка...' : 'Войти'}
          </button>
          {/* <button className="register-button" onClick={props.changeToRegister}>
            Register
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default LoginModal;
