import '../cart-item/cart-item.css';
import { useEffect } from 'react';
import {
  CartItemType,
} from '../../state/cart-reducer';
import { mainURL } from '../../config';
import { getStatusClass, getStatusOfOrder } from '../../utils/fetch';

type CompletedOrdersPropsType = {
  completedOrder: CartItemType;
  isExpanded: boolean;
  isAnyExpanded: boolean;
  onToggleUnits: () => void;
};

const CompletedOrder = ({
  completedOrder,
  isExpanded,
  isAnyExpanded,
  onToggleUnits,
}: CompletedOrdersPropsType) => {

  useEffect(() => {}, []);

  return (
    <>
      <div
        className={`cart-item ${isExpanded ? 'expanded' : ''}`}
        onClick={onToggleUnits}
        style={{ opacity: !isAnyExpanded || isExpanded ? 1 : 0.5 }}
      >
        <img src={mainURL + completedOrder.shop.logo} alt="" />
        <div className="cart-item-info">
          <p className="shop-name">{completedOrder.shop.name}</p>
          <p className={`status ${getStatusClass(completedOrder.status)}`} >{getStatusOfOrder(completedOrder.status)}</p>
        </div>
        <div className="cart-item-cost">
          <p className="item-cost">{completedOrder.order_total_price} ₸</p>
        </div>
        <p className="amount">{completedOrder.units_total_quantity}</p>
      </div>

      {completedOrder.order_units && (
        <div className={`order-units-list ${isExpanded ? 'show' : ''}`}>
          {completedOrder.order_units.map((unit) => (
            <div key={unit.id} className="order-unit">
              <img
                src={
                  mainURL + (unit.unit.images[0]?.image || '/fallback-image-url')
                }
                alt=""
                className="order-unit-img"
              />
              <div className="order-unit-info">
                <span className="unit-name">{unit.unit.name}</span>

              </div>
              <div className="unit-price">
                {unit.final_price} ₸ <span> x {unit.quantity} шт.</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CompletedOrder;
