import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import SearchForm from '../components/search-form/SearchForm';
import ShowModal from '../components/show-modal/ShowModal';
import SearchResults from '../components/search-results/SearchResults';

const SearchPage = () => {

  return (
    <div className='page search-page'>
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <div className="main-container">
        <SearchForm />
        <SearchResults />
        <ShowModal />
      </div>
    </div>
  );
};

export default SearchPage;
