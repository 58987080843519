import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CartItemType,
  decreaseUnitAmountTC,
  increaseUnitAmountTC,
  removeFromCartTC,
} from '../../state/cart-reducer';
import './cart-item.css';
import { mainURL } from '../../config';
import { changeModalTypeAC, modalEnumType } from '../../state/user-reducer';
import OrderUnit from '../order-unit/OrderUnit';
import DeliveryMethodSelector from '../delivery-method-selector/DeliveryMethodSelector';
import AddressInputForm from '../address-input-form/AddressInputForm';

export enum DeliveryEnumType {
  DELIVERY = 'delivery',
  SELF_PICKUP = 'selfpickup',
  NONE = 'none',
}

type CartItemsPropsType = {
  cartItem: CartItemType;
  isExpanded: boolean;
  isAnyExpanded: boolean;
  onToggleUnits: () => void;
};

const CartItem = ({
  cartItem,
  isExpanded,
  isAnyExpanded,
  onToggleUnits,
}: CartItemsPropsType) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [isAllowedToPay, setIsAllowedToPay] = useState(false);

  const [deliveryType, setDeliveryType] = useState<DeliveryEnumType>(
    DeliveryEnumType.NONE
  );

  // Increase unit quantity
  const handleIncreaseUnitAmount = (unitId: number) => {
    dispatch(increaseUnitAmountTC(unitId));
  };

  // Decrease unit quantity or remove item if quantity is 1
  const handleDecreaseUnitAmount = (unitId: number, unitQuantity: number) => {
    if (unitQuantity === 1) {
      dispatch(removeFromCartTC(unitId));

      if (cartItem.units_total_quantity === 1) onToggleUnits();
    } else {
      dispatch(decreaseUnitAmountTC(unitId));
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <div
        className={`cart-item ${isExpanded ? 'expanded' : ''}`}
        onClick={onToggleUnits}
        style={{ opacity: !isAnyExpanded || isExpanded ? 1 : 0.5 }}
      >
        <img src={mainURL + cartItem.shop.logo} alt="" />
        <div className="cart-item-info">
          <p className="shop-name">{cartItem.shop.name}</p>
        </div>
        <div className="cart-item-cost">
          <p className="item-cost">{cartItem.order_total_price} ₸</p>
        </div>
        <p className="amount">{cartItem.units_total_quantity}</p>
      </div>

      {cartItem.order_units && (
        <div className={`order-units-list ${isExpanded ? 'show' : ''}`}>
          {cartItem.order_units.map((unit) => (
            <OrderUnit
              key={unit.id}
              unit={unit}
              handleIncreaseUnitAmount={handleIncreaseUnitAmount}
              handleDecreaseUnitAmount={handleDecreaseUnitAmount}
            />
          ))}

          <DeliveryMethodSelector
            deliveryType={deliveryType}
            setDeliveryType={setDeliveryType}
            cartItem={cartItem}
          />

          {deliveryType === DeliveryEnumType.DELIVERY && <AddressInputForm />}

          <div className="cart-payment-button">
            <button
              onClick={() => {
                navigate(`/shopping-cart/${cartItem.id}`);
                dispatch(changeModalTypeAC(modalEnumType.CREDIT_CARD));
              }}
              disabled={deliveryType === DeliveryEnumType.NONE}
            >
              Перейти к оплате
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CartItem;
