import Header from '../components/header/Header'
import ProductDetail from '../components/product-detail/ProductDetail'
import ShowModal from '../components/show-modal/ShowModal'

const ProductDetailPage = () => {
  return (
    <div className="page product-detail-page">
      <div className="sticky-container">
        <Header />
      </div>
      <div className="main-container">
        <ProductDetail />
      </div>
      <ShowModal />
    </div>
  )
}

export default ProductDetailPage