import {jwtDecode} from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface JwtPayload {
  exp: number;
}

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(localStorage.getItem('accessToken'));

  useEffect(() => {
    // Set up a function to check the token's validity
    const checkToken = () => {
      if (token) {
        try {
          const decoded = jwtDecode<JwtPayload>(token);
          const isExpired = decoded.exp * 1000 < Date.now();
          if (isExpired) {
            localStorage.removeItem('accessToken');
            setIsAuthenticated(false);
          } else {
            setIsAuthenticated(true);
          }
        } catch (error) {
          console.error("Failed to decode or validate token", error);
          localStorage.removeItem('accessToken');
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    // Run the check on component mount or when the token changes
    checkToken();
  }, [navigate, token]);

  const logout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    setToken(null);
    navigate('/')
    window.location.reload();
  };

  return {
    isAuthenticated,
    logout,
  };
};
