import { Dispatch } from 'redux';
import { DeliveryAddress } from './cart-reducer';
import axios from 'axios';
import { CreditCardType, mainURL } from '../config';
import { mapProductFromAPI, ProductType } from './product-reducer';

export enum UserReducerEnumType {
  SET_USER_ADDRESSES = 'SET_USER_ADDRESSES',
  ADD_USER_ADDRESS = 'ADD_USER_ADDRESS',
  CHANGE_MODAL_TYPE = 'CHANGE_MODAL_TYPE',
  CHANGE_CART_CATEGORY = 'CHANGE_CART_CATEGORY',
  CHANGE_DEFAULT_ADDRESS = 'CHANGE_DEFAULT_ADDRESS',
  SET_SAVED_CARDS = 'SET_SAVED_CARDS',
  ADD_SAVED_CARD = 'ADD_SAVED_CARD',
  DELETE_SAVED_CARD = 'DELETE_SAVED_CARD',
  SET_USER_INFO = 'SET_USER_INFO',
  CHANGE_USER_INFO = 'CHANGE_USER_INFO',
  ADD_TO_FAVOURITES = 'ADD_TO_FAVOURITES',
  SET_FAVOURITES = 'SET_FAVOURITES',
  REMOVE_FROM_FAVOURITES = 'REMOVE_FROM_FAVOURITES',
  SET_ALERT = 'SET_ALERT',
}

export enum modalEnumType {
  LOGIN = 'login',
  REGISTER = 'register',
  CREDIT_CARD = 'credit-card',
  NONE = 'none',
  ORDER_TRACKING = 'order-tracking',
  PRODUCT_DETAIL = 'product-detail',
}

export type SavedCard = {
  id: number;
  account_id: string;
  token: string;
  cardholder_name: string;
  customer: number;
  card_type: CreditCardType;
  card_last_four: string;
};

export type UserType = {
  id: number;
  user_type: number;
  is_staff: boolean;
  is_active: boolean;
  phone_number: string;
  email: string;
  name: string;
  profile_picture: string;
  is_activated: boolean;
  addresses: number[];
  stripe_customer_id: null | string;
  shop_id: null | number;
  groups: number[];
  last_login: string;
  date_joined: string;
  favourite_products: number[];
  city: number;
  phone_number_to_call: string[];
  language: number;
};

export type UserReducerStateType = {
  userInfo: UserType;
  userAddresses: DeliveryAddress[];
  modalType: modalEnumType;
  defaultAddress: DeliveryAddress;
  savedCards: SavedCard[];
  favouriteProducts: ProductType[];
  alert: {
    message: string | null;
    severity: 'success' | 'error' | 'warning' | undefined;
  };
};

const initialState = {
  userInfo: {} as UserType,
  userAddresses: [],
  modalType: modalEnumType.NONE,
  defaultAddress: {} as DeliveryAddress,
  savedCards: [] as SavedCard[],
  favouriteProducts: [],
  alert: {
    message: null,
    severity: undefined,
  },
};

export type SetUserInfoActionType = {
  type: UserReducerEnumType.SET_USER_INFO;
  userInfo: UserType;
};

export type ChangeUserInfoActionType = {
  type: UserReducerEnumType.CHANGE_USER_INFO;
  userInfo: UserType;
};

export type SetUserAddressesActionType = {
  type: UserReducerEnumType.SET_USER_ADDRESSES;
  addresses: DeliveryAddress[];
};

export type AddUserAddressActionType = {
  type: UserReducerEnumType.ADD_USER_ADDRESS;
  address: DeliveryAddress;
};

export type ChangeDefaultAddressActionType = {
  type: UserReducerEnumType.CHANGE_DEFAULT_ADDRESS;
  address: DeliveryAddress;
};

export type ChangeModalTypeActionType = {
  type: UserReducerEnumType.CHANGE_MODAL_TYPE;
  modalType: modalEnumType;
};

export type SetSavedCardsActionType = {
  type: UserReducerEnumType.SET_SAVED_CARDS;
  savedCards: SavedCard[];
};

export type AddSavedCardActionType = {
  type: UserReducerEnumType.ADD_SAVED_CARD;
  savedCard: SavedCard;
};

export type DeleteSavedCardActionType = {
  type: UserReducerEnumType.DELETE_SAVED_CARD;
  cardId: number;
};

export type SetFavouritesActionType = {
  type: UserReducerEnumType.SET_FAVOURITES;
  favourites: ProductType[];
};

export type AddToFavouritesActionType = {
  type: UserReducerEnumType.ADD_TO_FAVOURITES;
  product: ProductType;
};

export type RemoveFromFavouritesActionType = {
  type: UserReducerEnumType.REMOVE_FROM_FAVOURITES;
  productId: number;
};

export type SetAlertActionType = {
  type: UserReducerEnumType.SET_ALERT;
  alert: {
    message: string | null;
    severity: 'success' | 'error' | undefined;
  };
};

export type userReducerActionType =
  | SetUserAddressesActionType
  | ChangeModalTypeActionType
  | AddUserAddressActionType
  | ChangeDefaultAddressActionType
  | SetSavedCardsActionType
  | AddSavedCardActionType
  | DeleteSavedCardActionType
  | SetUserInfoActionType
  | AddToFavouritesActionType
  | RemoveFromFavouritesActionType
  | SetFavouritesActionType
  | ChangeUserInfoActionType
  | SetAlertActionType;

export const userReducer = (
  state: UserReducerStateType = initialState,
  action: userReducerActionType
) => {
  switch (action.type) {
    case UserReducerEnumType.SET_USER_INFO:
      return { ...state, userInfo: action.userInfo };
    case UserReducerEnumType.CHANGE_USER_INFO:
      return { ...state, userInfo: { ...state.userInfo, ...action.userInfo } };
    case UserReducerEnumType.SET_USER_ADDRESSES:
      return { ...state, userAddresses: action.addresses };
    case UserReducerEnumType.ADD_USER_ADDRESS:
      return {
        ...state,
        userAddresses: [...state.userAddresses, action.address], // Correct way to add a new address
      };
    case UserReducerEnumType.CHANGE_DEFAULT_ADDRESS:
      return {
        ...state,
        userAddresses: state.userAddresses.map((address) =>
          address.id === action.address.id
            ? { ...address, is_default: true }
            : { ...address, is_default: false }
        ),
      };
    case UserReducerEnumType.CHANGE_MODAL_TYPE:
      return { ...state, modalType: action.modalType };
    case UserReducerEnumType.SET_SAVED_CARDS:
      return { ...state, savedCards: action.savedCards };
    case UserReducerEnumType.ADD_SAVED_CARD:
      return {
        ...state,
        savedCards: [...state.savedCards, action.savedCard],
      };
    case UserReducerEnumType.DELETE_SAVED_CARD:
      return {
        ...state,
        savedCards: state.savedCards.filter(
          (card) => card.id !== action.cardId
        ),
      };
    case UserReducerEnumType.ADD_TO_FAVOURITES:
      return {
        ...state,
        favouriteProducts: [...state.favouriteProducts, action.product],
      };
    case UserReducerEnumType.REMOVE_FROM_FAVOURITES:
      return {
        ...state,
        favouriteProducts: state.favouriteProducts.filter(
          (product) => product.id !== action.productId
        ),
      };
    case UserReducerEnumType.SET_FAVOURITES:
      return {
        ...state,
        favouriteProducts: action.favourites,
      };
    case UserReducerEnumType.SET_ALERT:
      console.log("alert", action.alert);
      return {
        ...state,
        alert: action.alert,
      };
    default:
      return state;
  }
};

export const setUserInfoAC = (userInfo: UserType): SetUserInfoActionType => {
  return {
    type: UserReducerEnumType.SET_USER_INFO,
    userInfo,
  };
};

export const changeUserInfoAC = (
  userInfo: UserType
): ChangeUserInfoActionType => {
  return {
    type: UserReducerEnumType.CHANGE_USER_INFO,
    userInfo,
  };
};

export const setUserAddressesAC = (
  addresses: DeliveryAddress[]
): SetUserAddressesActionType => {
  return {
    type: UserReducerEnumType.SET_USER_ADDRESSES,
    addresses,
  };
};

export const addUserAddressAC = (
  address: DeliveryAddress
): AddUserAddressActionType => {
  return {
    type: UserReducerEnumType.ADD_USER_ADDRESS,
    address,
  };
};

export const changeDefaultAddressAC = (
  address: DeliveryAddress
): ChangeDefaultAddressActionType => {
  return {
    type: UserReducerEnumType.CHANGE_DEFAULT_ADDRESS,
    address,
  };
};

export const changeModalTypeAC = (
  modalType: modalEnumType
): ChangeModalTypeActionType => {
  return {
    type: UserReducerEnumType.CHANGE_MODAL_TYPE,
    modalType,
  };
};

export const setSavedCardsAC = (
  cards: SavedCard[]
): SetSavedCardsActionType => {
  return {
    type: UserReducerEnumType.SET_SAVED_CARDS,
    savedCards: cards,
  };
};

export const addSavedCardAC = (card: SavedCard): AddSavedCardActionType => {
  return {
    type: UserReducerEnumType.ADD_SAVED_CARD,
    savedCard: card,
  };
};

export const deleteSavedCardAC = (
  cardId: number
): DeleteSavedCardActionType => {
  return {
    type: UserReducerEnumType.DELETE_SAVED_CARD,
    cardId,
  };
};

export const setFavouriteProductsAC = (
  favourites: ProductType[]
): SetFavouritesActionType => {
  return {
    type: UserReducerEnumType.SET_FAVOURITES,
    favourites,
  };
};

export const addToFavouritesAC = (
  product: ProductType
): AddToFavouritesActionType => {
  return {
    type: UserReducerEnumType.ADD_TO_FAVOURITES,
    product,
  };
};

export const removeFromFavouritesAC = (
  productId: number
): RemoveFromFavouritesActionType => {
  return {
    type: UserReducerEnumType.REMOVE_FROM_FAVOURITES,
    productId,
  };
};

export const setAlertAC = (
  message: string | null,
  severity: 'success' | 'error' | undefined
): SetAlertActionType => ({
  type: UserReducerEnumType.SET_ALERT,
  alert: { message, severity },
});

export const getUserInfoTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(mainURL + `/en/api/v1/users/profile/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      dispatch(setUserInfoAC(response.data));
    } catch (error) {
      console.error('Failed to get user info', error);
    }
  };
};

export const changeUserInfoTC = (userInfo: any) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.patch(
        mainURL + `/en/api/v1/users/profile/`,
        userInfo,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response.data);

      dispatch(changeUserInfoAC(response.data));
    } catch (err) {
      console.error('Failed to update user info', err);
    }
  };
};

export const getUserAddressesTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(
        mainURL + `/en/api/v1/users/addresses/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const addresses: DeliveryAddress[] = response.data;

      console.log('addresses: ', addresses);
      // Dispatch the addresses to the state
      dispatch(setUserAddressesAC(addresses));

      // Find the default address
      const defaultAddress = addresses.find((address) => address.is_default);

      // If a default address is found, update the defaultAddress in the state
      if (defaultAddress) {
        dispatch(changeDefaultAddressAC(defaultAddress));
      }
    } catch (error) {
      console.error('Failed to get addresses', error);
    }
  };
};

export const addUserAddressTC = (
  address_string: string,
  addressFromInput: string,
  newLatitude: number,
  newLongitude: number
) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
        mainURL + `/en/api/v1/users/addresses/`,
        {
          city_id: 1,
          address_string,
          detail_info: addressFromInput,
          lat: newLatitude,
          lng: newLongitude,
          is_default: true,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      dispatch(addUserAddressAC(response.data));
    } catch (error) {
      console.error('Failed to get addresses', error);
    }
  };
};

export const changeDefaultAddressTC = (addressId: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.patch(
        mainURL + `/en/api/v1/users/addresses/${addressId}/`,
        { is_default: true },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      dispatch(changeDefaultAddressAC(response.data));
    } catch (error) {
      console.error('Failed to get addresses', error);
    }
  };
};

export const getSavedCardsTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(
        mainURL + `/en/api/v1/users/cloud-payments-methods/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(setSavedCardsAC(response.data.results));
    } catch (error) {
      console.error('Failed to get saved cards:', error);
    }
  };
};

export const deleteSavedCardTC = (cardId: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      return;
    }

    try {
      const response = await axios.delete(
        mainURL + `/en/api/v1/users/cloud-payments-methods/${cardId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(deleteSavedCardAC(cardId));
    } catch (error) {
      console.error('Failed to delete saved card:', error);
    }
  };
};

export const getFavouriteProducts = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(
        mainURL +
          `/en/api/v1/users/favourite-products/
`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const favourites = response.data.results
        .map((result: any) => mapProductFromAPI(result))
        .filter((result: any) => result !== null);

      console.log('favourite products', favourites);

      dispatch(setFavouriteProductsAC(favourites));
    } catch (error) {
      console.error('Failed to get favourite products:', error);
    }
  };
};

export const AddToFavouritesTC = (productId: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
        mainURL + `/en/api/v1/users/add-to-favourites/`,
        { product_id: productId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('add to favourites', response.data);

      dispatch(addToFavouritesAC(response.data));
    } catch (error) {
      console.error('Failed to add product to favourites:', error);
    }
  };
};

export const removeFromFavouritesTC = (productId: number) => {
  return (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = axios.delete(
        mainURL + `/en/api/v1/users/remove-from-favourites/${productId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('remove from favourites', response);

      dispatch(removeFromFavouritesAC(productId));
    } catch (error) {
      console.error('Failed to remove product from favourites:', error);
    }
  };
};
