import { useEffect } from 'react';
import ActiveOrdersList from '../components/active-orders-list/ActiveOrdersList';
import CartCategories from '../components/cart-categories/CartCategories';
import Header from '../components/header/Header';
import ShowModal from '../components/show-modal/ShowModal';
import { useNavigate, useParams } from 'react-router-dom';

const ActiveOrdersPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  useEffect(() => {
    orderId && navigate('/active-orders');
  }, []);
  return (
    <div className="page">
      <div className="sticky-container">
        <Header />
        <CartCategories />
      </div>
      <ActiveOrdersList />
      <ShowModal />
    </div>
  );
};

export default ActiveOrdersPage;
