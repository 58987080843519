import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import ChatsList from '../components/chats-list/ChatsList';
import ShowModal from '../components/show-modal/ShowModal';

const ChatsPage = () => {
  return (
    <div className="page chats-page">
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <div className="main-container">
        <ShowModal />
        <ChatsList />
      </div>
    </div>
  );
};

export default ChatsPage;
