import { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import './cart-map.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { DeliveryAddress } from '../../state/cart-reducer';
import { fetchAddressByLocation } from '../../utils/fetch'; // Функция для получения адреса по координатам
import { mapBoxAccessToken } from '../../config';

// Типизация пропсов для компонента
type CartMapPropsType = {
  defaultAddress: DeliveryAddress | null;
  setSelectedAddress: (address: string | null) => void;
  setNewLongitude: (longitude: number | null) => void;
  setNewLatitude: (latitude: number | null) => void;
  selectedAddress: string | null;
  setIsError: (error: string | null) => void;
  newLatitude: number | null;
  newLongitude: number | null;
  markerPosition: [number, number];
  setMarkerPosition: (position: [number, number]) => void;
};

const CartMap = ({
  defaultAddress,
  setSelectedAddress,
  setNewLatitude,
  setNewLongitude,
  selectedAddress,
  setIsError,
  newLatitude,
  newLongitude,
  markerPosition,
  setMarkerPosition,
}: CartMapPropsType) => {
  const mapRef = useRef<mapboxgl.Map | null>(null); // Ссылка на экземпляр карты Mapbox
  const mapContainerRef = useRef<HTMLDivElement | null>(null); // Ссылка на контейнер карты
  const markerRef = useRef<mapboxgl.Marker | null>(null); // Ссылка на маркер

  const [isLoadingAddress, setIsLoadingAddress] = useState(false);

  useEffect(() => {
    if (mapRef.current || !mapContainerRef.current) return; // Инициализация карты один раз
    mapboxgl.accessToken = mapBoxAccessToken;

    // Инициализация карты Mapbox
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: markerPosition,
      zoom: 16,
    });

    // Инициализация маркера
    markerRef.current = new mapboxgl.Marker({
      color: 'red',
    })
      .setLngLat(markerPosition)
      .addTo(mapRef.current);

    // Обработка клика на карте
    mapRef.current.on('click', async (e) => {
      const { lng, lat } = e.lngLat;
      setMarkerPosition([lng, lat]);
      markerRef.current?.setLngLat([lng, lat]);
      setNewLatitude(lat);
      setNewLongitude(lng);
      setIsLoadingAddress(true);

      const address = await fetchAddressByLocation(lat, lng);
      if (address) {
        setSelectedAddress(address);
      }

      setIsLoadingAddress(false);
      setIsError(null);
    });
  }, []); // Важный момент: зависимость пустая, чтобы карта и маркер инициализировались только один раз

  // Обновление позиции маркера при изменении markerPosition
  useEffect(() => {
    if (markerRef.current && mapRef.current) {
      markerRef.current.setLngLat(markerPosition);
      mapRef.current.flyTo({ center: markerPosition });
    }
  }, [markerPosition]);

  return (
    <div className="cart-map">
      {/* Отображение выбранного адреса или индикатора загрузки */}
      <div className="map-address">
        <p>{isLoadingAddress ? 'Мы ищем адрес...' : selectedAddress}</p>
      </div>
      {/* Контейнер для карты */}
      <div
        id="map-container"
        ref={mapContainerRef} // Ссылка на div контейнер карты
        style={{ width: '100%', height: '600px' }} // Устанавливаем размеры контейнера
      />
    </div>
  );
};

export default CartMap;
