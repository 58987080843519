import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import ShowModal from '../components/show-modal/ShowModal';
import ProfileCard from '../components/change-profile/ChangeProfile';
import ProfileInfo from '../components/profile-info/ProfileInfo';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserInfoTC } from '../state/user-reducer';

const ProfilePage = () => {
  const dispatch = useDispatch<any>();
  return (
    <div>
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <div className="main-container">
        <ProfileInfo />
        <ShowModal />
      </div>
    </div>
  );
};

export default ProfilePage;
