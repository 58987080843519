export const mainURL = 'https://stubla.com';
export const mapBoxAccessToken =
  'pk.eyJ1IjoibWVsYW55Nzc3IiwiYSI6ImNsM2pvdjhlcTA0cTQzcHA2OTVjcnd0amEifQ.qapnEHWqZ89sf3MX8sFJ_Q';

export const cardTypeIcons = {
  Visa: 'visa.png',
  MasterCard: 'mastercard.png',
  Maestro: 'maestro.png',
  AmericanExpress: 'american-express.png',
  Discover: 'discover.png',
  Delta: 'delta.png',
  DirectDebit: 'direct-debit.png',
  Solo: 'solo.png',
  PayPal: 'paypal.png',
  GoogleCheckout: 'google-checkout.png',
  Switch: 'switch.png',
  WesternUnion: 'western-union.png',
};

export type CreditCardType = keyof typeof cardTypeIcons;

export interface PaymentResponse {
  ReasonCode: number;
  PublicId: string;
  TerminalUrl: string;
  TransactionId: number;
  Amount: number;
  Currency: string;
  CurrencyCode: number;
  PaymentAmount: number;
  PaymentCurrency: string;
  PaymentCurrencyCode: number;
  InvoiceId: number;
  AccountId: string;
  Email: string | null;
  Description: string;
  JsonData: string | null;
  CreatedDate: string;
  PayoutDate: string | null;
  PayoutDateIso: string | null;
  PayoutAmount: number | null;
  CreatedDateIso: string;
  AuthDate: string;
  AuthDateIso: string;
  ConfirmDate: string;
  ConfirmDateIso: string;
  CardFirstSix: string;
  CardLastFour: string;
  CardExpDate: string;
  CardType: string;
  CardProduct: string;
  CardCategory: string;
  EscrowAccumulationId: string | null;
  IssuerBankCountry: string;
  Issuer: string;
  CardTypeCode: number;
  Status: string;
  StatusCode: number;
  CultureName: string;
  Reason: string;
  CardHolderMessage: string;
}