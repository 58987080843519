import '../cart-item/cart-item.css';
import { useEffect } from 'react';
import { CartItemType } from '../../state/cart-reducer';
import './active-order.css';
import { mainURL } from '../../config';
import { useDispatch } from 'react-redux';
import { changeModalTypeAC, modalEnumType } from '../../state/user-reducer';
import { useNavigate } from 'react-router-dom';
import { getStatusClass, getStatusOfOrder } from '../../utils/fetch';

type ActiveOrdersPropsType = {
  activeOrder: CartItemType;
  isExpanded: boolean;
  isAnyExpanded: boolean;
  onToggleUnits: () => void;
};

const ActiveOrder = ({
  activeOrder,
  isExpanded,
  isAnyExpanded,
  onToggleUnits,
}: ActiveOrdersPropsType) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <div
        className={`cart-item ${isExpanded ? 'expanded' : ''}`}
        onClick={onToggleUnits}
        style={{ opacity: !isAnyExpanded || isExpanded ? 1 : 0.5 }}
      >
        <img src={mainURL + activeOrder.shop.logo} alt="" />
        <div className="cart-item-info">
          <p className="shop-name">{activeOrder.shop.name}</p>
          <p className={`status ${getStatusClass(activeOrder.status)}`} >{getStatusOfOrder(activeOrder.status)}</p>
        </div>
        <div className="cart-item-cost">
          <p className="item-cost">{activeOrder.order_total_price} ₸</p>
        </div>
        <p className="amount">{activeOrder.units_total_quantity}</p>
      </div>

      {activeOrder.order_units && (
        <div className={`order-units-list ${isExpanded ? 'show' : ''}`}>
          {activeOrder.order_units.map((unit) => (
            <div key={unit.id} className="order-unit">
              <img
                src={
                  mainURL +
                  (unit.unit.images[0]?.image || '/fallback-image-url')
                }
                alt=""
                className="order-unit-img"
              />
              <div className="order-unit-info">
                <p className="unit-name">{unit.unit.name}</p>
                <button
                  className="tracking-button"
                  onClick={() => {
                    navigate(`/active-orders/${activeOrder.id}`);
                    dispatch(changeModalTypeAC(modalEnumType.ORDER_TRACKING));
                  }}
                >
                  трекинг заказа
                </button>
              </div>
              <div className="unit-price">
                {unit.final_price} ₸ <span> x {unit.quantity} шт.</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ActiveOrder;
