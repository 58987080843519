import { useDispatch, useSelector } from 'react-redux';
import {
  CategoryType,
  setActiveCategoryAC,
} from '../../state/category-reducer';
import './category-item.css';
import { RootStateType } from '../../state/store';
import { useState } from 'react';

type CategoryItemPropsType = {
  category: CategoryType;
  handleChangeList: (category: CategoryType) => void;
};

const CategoryItem = ({
  category,
  handleChangeList,
}: CategoryItemPropsType) => {
  const dispatch = useDispatch<any>();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const activeCategory = useSelector<RootStateType, CategoryType>(
    (state) => state.categories.activeCategory
  );
  const isActive = activeCategory === category;

  return (
    <div className="category-item">
      <img
        src={category.image}
        alt=""
        onClick={() => handleChangeList(category)}
        onLoad={handleImageLoad}
        className={`${isActive ? 'active' : ''} ${
          isLoaded ? 'visible' : 'hidden'
        }`}
        loading="lazy"
      />
      <p>{category.name}</p>
    </div>
  );
};

export default CategoryItem;
