import { Dispatch } from 'redux';
import axios from 'axios';
import { mainURL } from '../config';
import { setProductsAC } from './product-reducer';
import { setSubcategoriesAC } from './subcategory-reducer';

// Enum для типов действий категорий
export enum CategoryActionTypes {
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY',
}

// Тип данных для категории
export type CategoryType = {
  id: number;
  name: string;
  image: string;
};

// Тип данных для состояния категории
export type CategoryStateType = {
  categories: CategoryType[];
  activeCategory: CategoryType;
};

// Типы действий для установки категорий и активной категории
export type SetCategoriesActionType = {
  type: CategoryActionTypes.SET_CATEGORIES;
  categories: CategoryType[];
};

export type SetActiveCategoryActionType = {
  type: CategoryActionTypes.SET_ACTIVE_CATEGORY;
  category: CategoryType;
};

// Начальное состояние для категорий
const initialState: CategoryStateType = {
  categories: [],
  activeCategory: {} as CategoryType,
};

// Объединенный тип действий для категорий
export type CategoryActionType =
  | SetCategoriesActionType
  | SetActiveCategoryActionType;

// Редьюсер для категорий
export const CategoryReducer = (
  state: CategoryStateType = initialState,
  action: CategoryActionType
): CategoryStateType => {
  switch (action.type) {
    case CategoryActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
        activeCategory: action.categories[0],
      };
    case CategoryActionTypes.SET_ACTIVE_CATEGORY:
      return { ...state, activeCategory: action.category };
    default:
      return state;
  }
};

// Action Creator для установки категорий
export const setCategoriesAC = (
  categories: CategoryType[]
): SetCategoriesActionType => {
  return {
    type: CategoryActionTypes.SET_CATEGORIES,
    categories,
  };
};

// Action Creator для установки активной категории
export const setActiveCategoryAC = (
  category: CategoryType
): SetActiveCategoryActionType => {
  return {
    type: CategoryActionTypes.SET_ACTIVE_CATEGORY,
    category,
  };
};

// Thunk-функция для получения категорий
export const getCategoriesTC = () => {
  return async (dispatch: Dispatch) => {
    const allCategories: CategoryType[] = [];
    let nextPage: number = 1;

    try {
      // Цикл для получения всех страниц с категориями
      while (nextPage) {
        // Выполняем запрос на получение категорий
        const response = await axios.get(
          mainURL + `/en/api/v1/shops/product-categories/?filter=for-suppliers&page=${nextPage}`
        );
        const { results, next } = response.data;

        // Добавляем категории с текущей страницы в общий массив
        allCategories.push(...results);

        // Проверяем, есть ли следующая страница, и обновляем переменную nextPage
        nextPage = next ? nextPage + 1 : 0;
      }

      // Отправляем действие с полным списком категорий
      dispatch(setCategoriesAC(allCategories));
    } catch (error) {
      // Обрабатываем ошибку, если что-то пошло не так при запросе
      console.error('Ошибка при загрузке категорий:', error);
    }
  };
};

// Thunk-функция для получения подкатегорий по ID категории
export const getSubcategoriesByIdTC = (categoryId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      // Выполняем запрос на получение подкатегорий по ID категории
      const response = await axios.get(
        mainURL + `/en/api/v1/shops/product-subcategories/?parent-category-id=${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      // Отправляем действие с подкатегориями, полученными из API
      dispatch(setSubcategoriesAC(response.data.results));
    } catch (error) {
      // Обрабатываем ошибку, если что-то пошло не так при запросе
      console.error('Ошибка при загрузке подкатегорий по ID категории:', error);
    }
  };
};
