import { useLocation, useNavigate } from 'react-router-dom';
import './cart-categories.css';
import GoBackButton from '../../elements/go-back-button/GoBackButton';

export enum CartCategoryEnumType {
  SHOPPING_CART = 'shopping-cart',
  ACTIVE_ORDERS = 'active-orders',
  COMPLETED_ORDERS = 'completed-orders'
}


// Компонент CartCategories
const CartCategories = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  // Константы для путей навигации
  const NAVIGATION_PATHS: Record<CartCategoryEnumType, string> = {
    [CartCategoryEnumType.SHOPPING_CART]: '/shopping-cart',
    [CartCategoryEnumType.ACTIVE_ORDERS]: '/active-orders',
    [CartCategoryEnumType.COMPLETED_ORDERS]: '/completed-orders',
  };

  // Функция для проверки активности пути
  const isActive = (path: string) => location === path;

  return (
    <div className="cart-categories">
      <GoBackButton />
      <ul className="cart-categories-list">
        <li
          className={
            isActive(NAVIGATION_PATHS[CartCategoryEnumType.SHOPPING_CART])
              ? 'active'
              : ''
          }
          onClick={() =>
            navigate(NAVIGATION_PATHS[CartCategoryEnumType.SHOPPING_CART])
          }
        >
          Корзина
        </li>
        <li
          className={
            isActive(NAVIGATION_PATHS[CartCategoryEnumType.ACTIVE_ORDERS])
              ? 'active'
              : ''
          }
          onClick={() =>
            navigate(NAVIGATION_PATHS[CartCategoryEnumType.ACTIVE_ORDERS])
          }
        >
          Активные заказы
        </li>
        <li
          className={
            isActive(NAVIGATION_PATHS[CartCategoryEnumType.COMPLETED_ORDERS])
              ? 'active'
              : ''
          }
          onClick={() =>
            navigate(NAVIGATION_PATHS[CartCategoryEnumType.COMPLETED_ORDERS])
          }
        >
          История заказов
        </li>
      </ul>
    </div>
  );
};

export default CartCategories;
