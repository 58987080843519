import './addresses-list.css';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { DeliveryAddress } from '../../state/cart-reducer';
import { mainURL } from '../../config';

const AddressesList = () => {
  const addresses = useSelector<RootStateType, DeliveryAddress[]>(
    (state) => state.userData.userAddresses
  );

  const defaultAddress = useSelector<RootStateType, DeliveryAddress>(
    (state) => state.userData.defaultAddress
  );

  return (
    <div className="addresses-list">
      {addresses.map((address) => {
        return (
          <div
            key={address.id}
            className={
              address.is_default
                ? 'address-item active'
                : 'address-item'
            }
          >
            <div className="address-item-info">
              <p className="address-name">{address.address_string}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AddressesList;
