import React from 'react'
import Header from '../components/header/Header'
import ChangeProfile from '../components/change-profile/ChangeProfile'
import ShowModal from '../components/show-modal/ShowModal'

const ChangeProfilePage = () => {
  return (
    <div className="page change-profile-page">
      <div className="sticky-container">
        <Header />
      </div>
      <div className="main-container">
        <ChangeProfile />
      </div>
      <ShowModal />
    </div>
  )
}

export default ChangeProfilePage