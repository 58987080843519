import React from 'react'
import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import ShowModal from '../components/show-modal/ShowModal';
import FavouritesList from '../components/favourites-list/FavouritesList';
import GoBackButton from '../elements/go-back-button/GoBackButton';

const FavouritesPage = () => {
    return (
        <div className="page chats-page">
          <GoBackButton />
          <div className="sticky-container">
            <Header />
            <Navbar />
          </div>
    
          <div className="main-container">
            <ShowModal />
            <FavouritesList />
          </div>
        </div>
      );
}

export default FavouritesPage