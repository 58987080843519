import { useState } from 'react';
import "../cart-container/cart-container.css"
import "./active-orders-list.css"
import { useSelector } from 'react-redux';
import { CartItemType } from '../../state/cart-reducer';
import ActiveOrder from '../active-order/ActiveOrder';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { RootStateType } from '../../state/store';

type ActiveOrdersListType = {
};

const ActiveOrdersList = () => {
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);
  const activeOrders = useSelector<RootStateType, CartItemType[]>(
    (state) => state.cartItems.activeOrders || []
  );

  const handleToggleUnits = (itemId: number) => {
    setExpandedItemId((prev) => (prev === itemId ? null : itemId));
  };

  return (
    activeOrders.length ? <div className="active-orders-list">
      {activeOrders.length > 0 && (
        <div className="main-container">
          <p className="cart-title">Активные заказы</p>

          <div className="cart-container-info">
            <div className="cart-items">
              <div className="cart-items-list">
                {activeOrders.map((item) => (
                  <ActiveOrder
                    key={item.id}
                    activeOrder={item}
                    isAnyExpanded={expandedItemId !== null}
                    isExpanded={expandedItemId === item.id}
                    onToggleUnits={() => handleToggleUnits(item.id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    :
    <div className='empty-orders'>
      <ProductionQuantityLimitsIcon />
      <p>Нет активных товаров</p>
    </div>
  );
};

export default ActiveOrdersList;
