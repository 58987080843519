import { useDispatch, useSelector } from 'react-redux';
import { ProductStateType, ProductType } from '../../state/product-reducer';
import { useAuth } from '../hoc/useAuth';
import './product-item.css';
import {
  AddToCartTC,
  CartItemType,
  decreaseUnitAmountTC,
  getCartItemsTC,
  increaseUnitAmountTC,
  removeFromCartTC,
} from '../../state/cart-reducer';
import { RootStateType } from '../../state/store';
import { mainURL } from '../../config';
import { useState } from 'react';
import { changeModalTypeAC, modalEnumType } from '../../state/user-reducer';
import { useNavigate } from 'react-router-dom';
import AddToCartButton from '../../elements/add-to-cart-button/AddToCartButton';

// Тип для пропсов компонента ProductItem
type ProductItemPropsType = {
  product: ProductType;
};

// Компонент ProductItem
const ProductItem = ({ product }: ProductItemPropsType) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const handleOpenProductDetail = () => {
    navigate(`/product-detail/${product.id}`);
  };

  return (
    <div className={`product-item`} onClick={handleOpenProductDetail}>
      <div className="product-item-content">
        <div className="product-image">
          {/* Изображение продукта */}
          <img src={`${mainURL}${product.image}`} />
        </div>
        <div className="product-info">
          {/* Цена продукта */}
          <p className="product-price">{product.price} ₸</p>
          {/* Название продукта */}
          <p className="product-name">{product.name}</p>
          <div className="product-item-buttons">
            {/* Кнопка для показа дополнительной информации */}
            <div className="">
              <button className="show-more-button">Подробнее</button>
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation(); // Prevents the click event from bubbling up to the parent
              }}
            >
              <AddToCartButton product={product} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
