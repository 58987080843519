import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { mainURL } from '../config';
import ActiveOrders from '../components/active-orders-list/ActiveOrdersList';
import { DeliveryEnumType } from '../components/cart-item/CartItem';

//constant values

export enum CartActionEnumType {
  ADD_TO_CART = 'ADD_TO_CART',
  SET_ITEMS_IN_CART = 'SET_ITEMS_IN_CART',
  SET_ACTIVE_ORDERS = 'SET_ACTIVE_ORDERS',
  ADD_ACTIVE_ORDER = 'ADD_ACTIVE_ORDER',
  SET_COMPLETED_ORDERS = 'SET_COMPLETED_ORDERS',
  ADD_COMPLETED_ORDER = 'ADD_COMPLETED_ORDER',
  INCREASE_UNIT_AMOUNT = 'INCREASE_UNIT_AMOUNT',
  DECREASE_UNIT_AMOUNT = 'DECREASE_UNIT_AMOUNT',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  UPDATE_SELF_PICKUP_STATUS = 'UPDATE_SELF_PICKUP_STATUS',
  MOVE_CART_ITEM_TO_ACTIVE = 'MOVE_CART_ITEM_TO_ACTIVE',
  MOVE_ACTIVE_TO_COMPLETED = 'MOVE_ACTIVE_TO_COMPLETED',
}

//cart items types

export type Location = {
  lat: number;
  lng: number;
};

export type Shop = {
  id: number;
  name: string;
  owner_id: number;
  logo: string;
  background_image: string;
  address: string;
  location: Location;
  basic_currency: number;
  phone_numbers: string[];
};

export type Unit = {
  id: number;
  name: string;
  old_price: number;
  price: number;
  images: Array<{ id: number; image: string }>;
  product_id: number;
  description: string;
  num_in_stock: number;
};

export type OrderUnit = {
  id: number;
  unit: Unit;
  quantity: number;
  final_price: number;
};

export type Customer = {
  id: number;
  name: string;
  phone_number: string;
  profile_picture: string;
};

export type DeliveryAddress = {
  id: number;
  address_string: string;
  detail_info: string;
  is_default: boolean;
  lat: number;
  lng: number;
};

export type CartItemType = {
  id: number;
  shop: Shop;
  customer: Customer;
  delivery_address: DeliveryAddress;
  self_pickup: boolean;
  units_total_quantity: number;
  order_units: OrderUnit[];
  different_units_num: number;
  status: number;
  order_total_price: number;
  delivery_price: number;
  grand_total_with_delivery: number;
};

//cart state

export type CartStateType = {
  itemsInCart: CartItemType[];
  activeOrders: CartItemType[];
  completedOrders: CartItemType[];
};

const initialState: CartStateType = {
  itemsInCart: [],
  activeOrders: [],
  completedOrders: [],
};

// cart action types

type AddToCartActionType = {
  type: CartActionEnumType.ADD_TO_CART;
  cartItem: CartItemType;
};

type SetItemsInCartActionType = {
  type: CartActionEnumType.SET_ITEMS_IN_CART;
  itemsInCart: CartItemType[];
};

type SetActiveOrdersActionType = {
  type: CartActionEnumType.SET_ACTIVE_ORDERS;
  activeOrders: CartItemType[];
};

type AddActiveOrderActionType = {
  type: CartActionEnumType.ADD_ACTIVE_ORDER;
  cartItem: CartItemType;
};

type SetCompletedOrdersActionType = {
  type: CartActionEnumType.SET_COMPLETED_ORDERS;
  completedOrders: CartItemType[];
};

type AddCompletedOrderActionType = {
  type: CartActionEnumType.ADD_COMPLETED_ORDER;
  cartItem: CartItemType;
};

type IncreaseUnitAmountActionType = {
  type: CartActionEnumType.INCREASE_UNIT_AMOUNT;
  unitId: number;
};

type DecreaseUnitAmountActionType = {
  type: CartActionEnumType.DECREASE_UNIT_AMOUNT;
  unitId: number;
};

type RemoveFromCartActionType = {
  type: CartActionEnumType.REMOVE_FROM_CART;
  unitId: number;
};

type UpdateSelfPickupStatusActionType = {
  type: CartActionEnumType.UPDATE_SELF_PICKUP_STATUS;
  id: number;
  selfPickup: boolean;
  deliveryPrice: number;
};

type MoveCartItemToActiveActionType = {
  type: CartActionEnumType.MOVE_CART_ITEM_TO_ACTIVE;
  cartItemId: number;
};

type MoveActiveToCompletedActionType = {
  type: CartActionEnumType.MOVE_ACTIVE_TO_COMPLETED;
  cartItemId: number;
};

export type CartAction =
  | AddToCartActionType
  | SetItemsInCartActionType
  | IncreaseUnitAmountActionType
  | DecreaseUnitAmountActionType
  | RemoveFromCartActionType
  | SetActiveOrdersActionType
  | SetCompletedOrdersActionType
  | AddActiveOrderActionType
  | AddCompletedOrderActionType
  | UpdateSelfPickupStatusActionType
  | MoveCartItemToActiveActionType;

// Action creators
export const setItemsInCart = (
  itemsInCart: CartItemType[]
): SetItemsInCartActionType => ({
  type: CartActionEnumType.SET_ITEMS_IN_CART,
  itemsInCart,
});

export const addToCart = (cartItem: CartItemType): AddToCartActionType => ({
  type: CartActionEnumType.ADD_TO_CART,
  cartItem,
});

export const increaseUnitAmount = (
  unitId: number
): IncreaseUnitAmountActionType => ({
  type: CartActionEnumType.INCREASE_UNIT_AMOUNT,
  unitId,
});

export const decreaseUnitAmount = (
  unitId: number
): DecreaseUnitAmountActionType => ({
  type: CartActionEnumType.DECREASE_UNIT_AMOUNT,
  unitId,
});

export const removeFromCart = (unitId: number): RemoveFromCartActionType => ({
  type: CartActionEnumType.REMOVE_FROM_CART,
  unitId,
});

export const cartReducer = (
  state: CartStateType = initialState,
  action: CartAction
): CartStateType => {
  switch (action.type) {
    case CartActionEnumType.SET_ITEMS_IN_CART:
      return {
        ...state,
        itemsInCart: action.itemsInCart,
      };

    case CartActionEnumType.SET_COMPLETED_ORDERS:
      return {
        ...state,
        completedOrders: action.completedOrders,
      };

    case CartActionEnumType.SET_ACTIVE_ORDERS:
      return {
        ...state,
        activeOrders: action.activeOrders,
      };

    case CartActionEnumType.ADD_TO_CART:
      return {
        ...state,
        itemsInCart: [...state.itemsInCart, action.cartItem],
      };

    case CartActionEnumType.INCREASE_UNIT_AMOUNT:
    case CartActionEnumType.DECREASE_UNIT_AMOUNT:
      return {
        ...state,
        itemsInCart: state.itemsInCart.map((cartItem) => {
          const updatedUnits = cartItem.order_units.map((orderUnit) => {
            if (orderUnit.id === action.unitId) {
              const newQuantity =
                action.type === CartActionEnumType.INCREASE_UNIT_AMOUNT
                  ? orderUnit.quantity + 1
                  : orderUnit.quantity - 1;

              return {
                ...orderUnit,
                quantity: newQuantity,
                final_price: newQuantity * orderUnit.unit.price,
              };
            }
            return orderUnit;
          });

          const newOrderTotalPrice = updatedUnits.reduce(
            (total, unit) => total + unit.final_price,
            0
          );

          return {
            ...cartItem,
            order_units: updatedUnits.filter((unit) => unit.quantity > 0),
            units_total_quantity: updatedUnits.reduce(
              (total, unit) => total + unit.quantity,
              0
            ),
            order_total_price: newOrderTotalPrice,
          };
        }),
      };

    case CartActionEnumType.REMOVE_FROM_CART:
      // Update itemsInCart after removal
      const updatedCartItems = state.itemsInCart
        .map((cartItem) => {
          // Filter out the removed order unit
          const updatedOrderUnits = cartItem.order_units.filter(
            (orderUnit) => orderUnit.id !== action.unitId
          );

          // Recalculate total quantities and prices
          const unitsTotalQuantity = updatedOrderUnits.reduce(
            (total, unit) => total + unit.quantity,
            0
          );
          const orderTotalPrice = updatedOrderUnits.reduce(
            (total, unit) => total + unit.final_price,
            0
          );

          return {
            ...cartItem,
            order_units: updatedOrderUnits,
            units_total_quantity: unitsTotalQuantity,
            order_total_price: orderTotalPrice,
          };
        })
        // Remove cart items where all order units have been removed
        .filter((cartItem) => cartItem.order_units.length > 0);

      return {
        ...state,
        itemsInCart: updatedCartItems, // Update cart with recalculated totals
      };

    case CartActionEnumType.UPDATE_SELF_PICKUP_STATUS:
      return {
        ...state,
        itemsInCart: state.itemsInCart.map((cartItem) => {
          if (cartItem.id === action.id) {
            return {
              ...cartItem,
              self_pickup: action.selfPickup,
              delivery_price: action.deliveryPrice,
            };
          }
          return cartItem;
        }),
      };

    // case CartActionEnumType.ADD_ACTIVE_ORDER:

    case CartActionEnumType.MOVE_CART_ITEM_TO_ACTIVE:
      // Find the cart item to be moved
      const cartItemToMove = state.itemsInCart.find(
        (item) => item.id === action.cartItemId
      );
      if (!cartItemToMove) {
        return state; // If item is not found, return state without changes
      }

      // Filter out the cart item from itemsInCart
      const updatedItemsInCart = state.itemsInCart.filter(
        (item) => item.id !== action.cartItemId
      );

      // Add the cart item to activeOrders
      return {
        ...state,
        itemsInCart: updatedItemsInCart,
        activeOrders: [...state.activeOrders, cartItemToMove],
      };
    default:
      return state;
  }
};

//ACTION CREATORS

export const setItemsInCartAC = (
  itemsInCart: CartItemType[]
): SetItemsInCartActionType => ({
  type: CartActionEnumType.SET_ITEMS_IN_CART,
  itemsInCart, // Updated action name for setting items in the cart
});

export const setActiveOrdersAC = (
  activeOrders: CartItemType[]
): SetActiveOrdersActionType => ({
  type: CartActionEnumType.SET_ACTIVE_ORDERS,
  activeOrders,
});

export const setCompletedOrdersAC = (
  completedOrders: CartItemType[]
): SetCompletedOrdersActionType => ({
  type: CartActionEnumType.SET_COMPLETED_ORDERS,
  completedOrders,
});

export const addToCartAC = (
  cartItemData: CartItemType
): AddToCartActionType => {
  const {
    id,
    shop,
    customer,
    delivery_address,
    self_pickup,
    units_total_quantity,
    order_units,
    different_units_num,
    status,
    order_total_price,
    delivery_price,
    grand_total_with_delivery,
  } = cartItemData;

  const cartItem: CartItemType = {
    id,
    shop,
    customer,
    delivery_address,
    self_pickup,
    units_total_quantity,
    order_units: order_units.map(({ id, unit, quantity, final_price }) => ({
      id,
      unit: { ...unit }, // Spread all unit properties directly
      quantity,
      final_price,
    })),
    different_units_num,
    status,
    order_total_price,
    delivery_price,
    grand_total_with_delivery,
  };

  return {
    type: CartActionEnumType.ADD_TO_CART,
    cartItem,
  };
};

export const increaseUnitAmountAC = (
  unitId: number
): IncreaseUnitAmountActionType => ({
  type: CartActionEnumType.INCREASE_UNIT_AMOUNT,
  unitId, // Action creator to increase the quantity of a unit
});

export const decreaseUnitAmountAC = (
  unitId: number
): DecreaseUnitAmountActionType => ({
  type: CartActionEnumType.DECREASE_UNIT_AMOUNT,
  unitId, // Action creator to decrease the quantity of a unit
});

export const removeFromCartAC = (unitId: number): RemoveFromCartActionType => ({
  type: CartActionEnumType.REMOVE_FROM_CART,
  unitId, // Action creator to remove a unit from the cart
});

export const updateSelfPickupStatusAC = (
  id: number,
  selfPickup: boolean,
  deliveryPrice: number
): UpdateSelfPickupStatusActionType => ({
  type: CartActionEnumType.UPDATE_SELF_PICKUP_STATUS,
  id,
  selfPickup,
  deliveryPrice,
});

export const moveCartItemToActiveAC = (
  cartItemId: number
): MoveCartItemToActiveActionType => ({
  type: CartActionEnumType.MOVE_CART_ITEM_TO_ACTIVE,
  cartItemId,
});

export const MoveActiveToCompletedAC = (cartItemId: number): MoveActiveToCompletedActionType => ({
  type: CartActionEnumType.MOVE_ACTIVE_TO_COMPLETED,
  cartItemId,
})

//THUNK CREATORS

export const getCartItemsTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(mainURL + '/en/api/v1/shops/shopping-cart/', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
      });

      const cartItems = response.data;

      const fetchPromises = cartItems.map(async (item: CartItemType) => {
        const newItem = await fetchUnitDetails(item.id);
        return newItem;
      });

      const resolvedItems = await Promise.all(fetchPromises);

      dispatch(setItemsInCart(resolvedItems));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };
};

export const getActiveOrdersTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(mainURL + '/en/api/v1/shops/orders/active', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
      });

      const cartItems = response.data;

      const fetchPromises = cartItems.map(async (item: CartItemType) => {
        const newItem = await fetchUnitDetails(item.id);
        return newItem;
      });

      const resolvedItems = await Promise.all(fetchPromises);

      dispatch(setActiveOrdersAC(resolvedItems));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };
};

export const getCompletedOrdersTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(mainURL + '/en/api/v1/shops/orders/completed', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
      });

      const cartItems = response.data;

      const fetchPromises = cartItems.map(async (item: CartItemType) => {
        const newItem = await fetchUnitDetails(item.id);
        return newItem;
      });

      const resolvedItems = await Promise.all(fetchPromises);

      dispatch(setCompletedOrdersAC(resolvedItems));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };
};

export const AddToCartTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
        mainURL + '/en/api/v1/shops/add-to-cart/',
        { unit_id: id },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
        }
      );

    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };
};

export const removeFromCartTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.delete(mainURL + '/en/api/v1/shops/order-units/', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
        data: {
          order_unit_id: id,
        },
      });

      dispatch(removeFromCartAC(id));
    } catch (error) {
      console.error('Error removing from cart:', error);
    }
  };
};

export const increaseUnitAmountTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
        mainURL + '/en/api/v1/shops/order-units/increase/',
        { order_unit_id: id },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(increaseUnitAmountAC(id));
    } catch (error) {
      console.error('Error increasing unit amount:', error);
    }
  };
};

export const decreaseUnitAmountTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
        mainURL + '/en/api/v1/shops/order-units/decrease/',
        { order_unit_id: id },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(decreaseUnitAmountAC(id));
    } catch (error) {
      console.error('Error decreasing unit amount:', error);
    }
  };
};

export const fetchUnitDetails = async (unitId: number) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('No access token found');
    return;
  }

  try {
    const response = await axios.get(mainURL + `/en/api/v1/shops/orders/${unitId}/`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching unit details:', error);
  }
};

export const changeSelfPickupTC = (selfPickup: boolean, id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error('No access token found.');
      return { success: false, message: 'No access token found' };
    }

    try {
      const response = await axios.patch(
        mainURL + `/en/api/v1/shops/orders/${id}/`,
        { self_pickup: selfPickup },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      dispatch(
        updateSelfPickupStatusAC(id, selfPickup, response.data.delivery_price)
      );

      return {
        success: true,
        message: 'Self-pickup status updated successfully',
      };
    } catch (error) {
      console.error('Error updating delivery method:', error);
      return { success: false, message: 'Error updating delivery method' };
    }
  };
};
