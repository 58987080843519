import React from 'react';
import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import ShowModal from '../components/show-modal/ShowModal';
import AddressesList from '../components/addresses-list/AddressesList';
import GoBackButton from '../elements/go-back-button/GoBackButton';

const AddressesPage = () => {
  return (
    <div className="page chats-page">
      <GoBackButton />
      <div className="sticky-container">
        <Header />
        <Navbar />
      </div>

      <div className="main-container">
        <ShowModal />
        <AddressesList />
      </div>
    </div>
  );
};

export default AddressesPage;
