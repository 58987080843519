import { useSelector } from 'react-redux';
import './profile-info.css';
import { RootStateType } from '../../state/store';
import { UserType } from '../../state/user-reducer';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hoc/useAuth';
import { mainURL } from '../../config';

const ProfileInfo = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const userInfo = useSelector<RootStateType, UserType>(
    (state) => state.userData.userInfo
  );

  // Format the date_joined to a human-readable format
  const formattedDateJoined = new Date(
    Number(userInfo.date_joined) * 1000
  ).toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <div className="profile-info">
      <img
        src={mainURL + userInfo.profile_picture || '/static/images/no-profile.png'}
        alt=""
      />
      <div className="user-info">
        <p className="name"> {userInfo.name}</p>

        <p className="city">
          <NearMeOutlinedIcon />
          {userInfo.city === 1 ? 'Алматы' : 'Астана'}
        </p>

        <p className="created-at">{formattedDateJoined}</p>
      </div>

      <div className="options">
        <p onClick={() => navigate('/profile/change-profile')}>
          Изменить данные
        </p>
        <p className="red" onClick={() => navigate('/favourites')}>
          Избранные
        </p>
        <p className="red" onClick={() => navigate('/completed-orders')}>
          История заказов
        </p>
        <p onClick={() => navigate('/addresses')}>Адреса доставки</p>
        <p onClick={() => navigate('/support')}>Поддержка</p>
        <p className="red" onClick={logout}>
          Выйти
        </p>
      </div>
    </div>
  );
};

export default ProfileInfo;
