import React from 'react';
import './shops-list.css';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { ShopType } from '../../state/shops-reducer';
import { mainURL } from '../../config';

const ShopsList = () => {
  const shops = useSelector<RootStateType, ShopType[]>(
    (state) => state.shopData.shops
  );
  return (
    <div className="shops-list">
      <div className="shops-list-container">
        {shops.map((shop) => (
          <div key={shop.id} className="shop-item">
            <img src={mainURL + shop.logo} alt="" />
            <p>{shop.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopsList;
