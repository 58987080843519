import { Dispatch } from 'redux';
import axios from 'axios';
import { mainURL } from '../config';
import {
  mapProductFromAPI,
} from './product-reducer';

// Enum для типов действий подкатегорий
export enum SubcategoryActionTypes {
  SET_SUBCATEGORIES = 'SET_SUBCATEGORIES',
  SET_ACTIVE_SUBCATEGORY = 'SET_ACTIVE_SUBCATEGORY',
}

// Тип данных для подкатегории
export type SubcategoryType = {
  id: number;
  name: string;
  image: string;
  parent_category_info: {
    id: number;
    name: string;
  };
};

// Тип состояния подкатегорий
export type SubcategoryStateType = {
  subcategories: SubcategoryType[];
  activeSubcategory: SubcategoryType | null;
};

export const initialState = {
  subcategories: [],
  activeSubcategory: null,
};

// Типы действий для установки подкатегорий и активной подкатегории
export type SetActiveSubcategoryActionType = {
  type: SubcategoryActionTypes.SET_ACTIVE_SUBCATEGORY;
  subcategory: SubcategoryType | null;
};

export type SetSubcategoriesActionType = {
  type: SubcategoryActionTypes.SET_SUBCATEGORIES;
  subcategories: SubcategoryType[];
};

export type SubcategoryActionType =
  | SetSubcategoriesActionType
  | SetActiveSubcategoryActionType;

export const SubcategoryReducer = (
  state: SubcategoryStateType = initialState,
  action: SubcategoryActionType
): SubcategoryStateType => {
  switch (action.type) {
    case SubcategoryActionTypes.SET_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.subcategories,
      };
    case SubcategoryActionTypes.SET_ACTIVE_SUBCATEGORY:
      return { ...state, activeSubcategory: action.subcategory };
    default:
      return state;
  }
};

// Action Creator для установки подкатегорий
export const setSubcategoriesAC = (
  subcategories: SubcategoryType[]
): SetSubcategoriesActionType => ({
  type: SubcategoryActionTypes.SET_SUBCATEGORIES,
  subcategories,
});

// Action Creator для установки активной подкатегории
export const setActiveSubcategoryAC = (
  subcategory: SubcategoryType | null
): SetActiveSubcategoryActionType => ({
  type: SubcategoryActionTypes.SET_ACTIVE_SUBCATEGORY,
  subcategory,
});

// Thunk-функция для получения подкатегорий
export const getSubcategoriesTC = () => {
  return async (dispatch: Dispatch) => {
    // Массив для хранения всех подкатегорий
    const allSubcategories: SubcategoryType[] = [];
    let nextPage: number = 1;

    try {
      // Цикл для получения всех страниц с подкатегориями
      while (nextPage) {
        // Выполняем запрос на получение подкатегорий
        const response = await axios.get(
          mainURL + `/en/api/v1/shops/product-subcategories/?filter=for-suppliers&page=${nextPage}`
        );
        const { results, next } = response.data;

        // Добавляем подкатегории с текущей страницы в общий массив
        allSubcategories.push(...results);

        // Проверяем, есть ли следующая страница, и обновляем переменную nextPage
        nextPage = next ? nextPage + 1 : 0;
      }

      // Отправляем действие с полным списком подкатегорий
      dispatch(setSubcategoriesAC(allSubcategories));
    } catch (error) {
      // Обрабатываем ошибку, если что-то пошло не так при запросе
      console.error('Ошибка при загрузке подкатегорий:', error);
    }
  };
};

// export const getProductsBySubcategoryNameTC = (subcategoryName: string) => {
//   return async (dispatch: Dispatch) => {
//     // const token = localStorage.getItem('accessToken');
//     // const allProducts: any[] = [];
//     // let nextPage: number = 1;

//     try {
//       // while (nextPage) {
//       const response = await axios.get(
//         mainURL + `/en/api/v1/shops/products/?subcategory-name=${subcategoryName}`
//       );

//       // const { results, next } = response.data;

//       // allProducts.push(...results);

//       // nextPage = next ? nextPage + 1 : 0;
//       // }

//       const products = response.data.results.map((product: any) => {
//         return mapProductFromAPI(product);
//       });

//       dispatch(setProductsBySubcategoryAC(products));
//     } catch (error) {
//       console.error('Unexpected error:', error);
//     }
//   };
// };
