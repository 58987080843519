import React, { useState } from 'react';
import './search-form.css';
import { useDispatch } from 'react-redux';
import { getSearchResults } from '../../state/product-reducer';

const SearchForm = () => {
  const dispatch = useDispatch<any>();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (searchTerm.trim().length > 0) {
      dispatch(getSearchResults(searchTerm));
      console.log('Searching for:', searchTerm);
    }
  };

  return (
    <form className="search-form" onSubmit={handleSearch}>
      <input
        type="text"
        placeholder="Поиск..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <button type="submit" className="search-button">
        Поиск
      </button>
    </form>
  );
};

export default SearchForm;
