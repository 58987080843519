import { useDispatch, useSelector } from 'react-redux';
import './category-list.css';
import { RootStateType } from '../../state/store';
import SubcategoryItem from '../category-item/SubcategoryItem';
import {
  setActiveSubcategoryAC,
  SubcategoryStateType,
} from '../../state/subcategory-reducer';
import { CategoryStateType } from '../../state/category-reducer';
import { useEffect } from 'react';

type SubcategoryListPropsType = {};

const SubcategoryList = (props: SubcategoryListPropsType) => {
  const dispatch = useDispatch();
  const { activeCategory, categories } = useSelector<
    RootStateType,
    CategoryStateType
  >((state) => state.categories);

  // Получаем все подкатегории из хранилища
  const { subcategories: allSubcategories, activeSubcategory } = useSelector<
    RootStateType,
    SubcategoryStateType
  >((state) => state.subcategories);

  // Фильтрация подкатегорий на основе активной категории
  const filteredSubcategories = allSubcategories.filter(
    (subcategory) =>
      activeCategory?.id && // Ensure activeCategory is not undefined
      subcategory.parent_category_info?.id === activeCategory.id // Ensure parent_category_info is not undefined
  );

  return (
    <div className="categories">
      {activeCategory && activeCategory !== categories[0] && (
        <div className="category-list center">
          {filteredSubcategories.length > 0 ? (
            filteredSubcategories.map((subcategory) => (
              <SubcategoryItem
                subcategory={subcategory}
                key={subcategory.id}
                isActive={activeSubcategory?.id === subcategory.id} // Compare using IDs
              />
            ))
          ) : (
            <p></p>
          )}
        </div>
      )}
    </div>
  );
};

export default SubcategoryList;
