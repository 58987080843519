import { useNavigate, useParams } from 'react-router-dom';
import CartCategories from '../components/cart-categories/CartCategories';
import CompletedOrdersList from '../components/completed-orders-list/CompletedOrdersList';
import Header from '../components/header/Header';
import ShowModal from '../components/show-modal/ShowModal';
import { useEffect } from 'react';

const CompletedOrdersPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    orderId && navigate('/completed-orders');
  }, []);
  return (
    <div>
      <div className="sticky-container">
        <Header />
        <CartCategories />
      </div>

      <CompletedOrdersList />
      <ShowModal />
    </div>
  );
};

export default CompletedOrdersPage;
