import { useDispatch } from 'react-redux';
import { CartItemType, changeSelfPickupTC } from '../../state/cart-reducer';
import { DeliveryEnumType } from '../cart-item/CartItem';

type DeliveryMethodSelectorProps = {
  deliveryType: DeliveryEnumType;
  setDeliveryType: (deliveryType: DeliveryEnumType) => void;
  cartItem: CartItemType;
};

const DeliveryMethodSelector = ({
  deliveryType,
  setDeliveryType,
  cartItem,
}: DeliveryMethodSelectorProps) => {
  const dispatch = useDispatch<any>();
  return (
    <>
      <div className="unit-delivery-buttons">
        <button
          className={
            deliveryType === DeliveryEnumType.DELIVERY
              ? 'choosed-delivery-button'
              : ''
          }
          onClick={() => {
            dispatch(changeSelfPickupTC(false, cartItem.id));
            setDeliveryType(DeliveryEnumType.DELIVERY);
          }}
        >
          Доставка
        </button>
        <button
          className={
            deliveryType === DeliveryEnumType.SELF_PICKUP
              ? 'choosed-delivery-button'
              : ''
          }
          onClick={() => {
            dispatch(changeSelfPickupTC(true, cartItem.id))
            setDeliveryType(DeliveryEnumType.SELF_PICKUP);
          }}
        >
          Самовывоз
        </button>
      </div>
      {deliveryType === DeliveryEnumType.SELF_PICKUP && (
        <div className="Order_shop-address">
          Адрес магазина: {cartItem.shop.address}
        </div>
      )}
    </>
  );
};

export default DeliveryMethodSelector;
