import React, { useState } from 'react';
import './add-to-cart-button.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddToCartTC,
  CartItemType,
  getCartItemsTC,
} from '../../state/cart-reducer';
import { changeModalTypeAC, modalEnumType } from '../../state/user-reducer';
import { RootStateType } from '../../state/store';
import { ProductStateType, ProductType } from '../../state/product-reducer';
import { useAuth } from '../../components/hoc/useAuth';

type AddToCartButtonProps = {
  product: ProductType;
};

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ product }) => {
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(false);

  // Извлекаем элементы корзины из хранилища Redux
  const cartItems = useSelector<RootStateType, CartItemType[]>(
    (state) => state.cartItems.itemsInCart
  );

  // Находим существующий элемент в корзине для текущего продукта
  const existingCartItem = cartItems.find(
    (item) =>
      Array.isArray(item.order_units) &&
      item.order_units.some((unit) => unit.unit.product_id === product.id)
  );

  const isOutOfStock = Array.isArray(product.units) && product.units.every((unit) => unit.num_in_stock === 0);

  // Обработчик для открытия модального окна входа, если пользователь не авторизован
  const handleOpenLoginModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(changeModalTypeAC(modalEnumType.LOGIN));
  };

  // Обработчик добавления продукта в корзину
  const handleAddToCart = async () => {
    setIsLoading(true);
    setError(null); // Reset error on each new attempt
    try {
      await dispatch(AddToCartTC(product.units[0].id));
      await dispatch(getCartItemsTC());
    } catch (error) {
      console.error('Error adding item to cart:', error);
      setError('Не удалось добавить товар в корзину. Попробуйте еще раз.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {existingCartItem ? (
        <button className="add-cart-button added-to-cart" disabled>
          Добавлено
        </button>
      ) : (
        <button
          className="add-cart-button add-to-cart-button"
          onClick={isAuthenticated ? handleAddToCart : handleOpenLoginModal}
          disabled={isLoading || isOutOfStock} // Отключаем кнопку, если запрос в процессе выполнения
        >
          {isLoading ? 'Добавление...' : isOutOfStock ? 'Нет в наличии' : 'В корзину'}
        </button>
      )}
    </>
  );
};

export default AddToCartButton;
