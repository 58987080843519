import { useNavigate, useParams } from 'react-router-dom';
import './product-detail.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../state/store';
import { ProductStateType, ProductType } from '../../state/product-reducer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { mainURL } from '../../config';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
  AddToFavouritesTC,
  changeModalTypeAC,
  modalEnumType,
  removeFromFavouritesTC,
} from '../../state/user-reducer';
import {
  AddToCartTC,
  CartItemType,
  getCartItemsTC,
} from '../../state/cart-reducer';
import { useAuth } from '../hoc/useAuth';
import AddToCartButton from '../../elements/add-to-cart-button/AddToCartButton';
import GoBackButton from '../../elements/go-back-button/GoBackButton';

const ProductDetail = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<number | null>(null);
  const [isFavouriteLocal, setIsFavouriteLocal] = useState<boolean>(false);

  /// Fetch the product
  const product = useSelector<RootStateType, ProductType | null>((state) => {
    return (
      state.products.products.find((p) => p.id === Number(productId)) ||
      state.products.searchResults.find((p) => p.id === Number(productId)) ||
      state.userData.favouriteProducts.find(
        (p) => p.id === Number(productId)
      ) ||
      null
    );
  });

  const favourites = useSelector<RootStateType, ProductType[]>(
    (state) => state.userData.favouriteProducts
  );

  const isFavourite = favourites.some(
    (favouriteProduct) => favouriteProduct.id === product?.id
  );

  const handlePropertyChange = (propertyId: number) => {
    setSelectedProperty(propertyId);
  };

  const mainUnit =
    product?.units?.find(
      (unit) =>
        unit.verbose_properties[0]?.property_value_id === selectedProperty
    ) || product?.units[0];

  const openImageModal = (imageUrl: string) => {
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const handleAddToFavourites = () => {
    if (product) {
      dispatch(AddToFavouritesTC(product.id));
      setIsFavouriteLocal(true); // Optimistically set favorite
    }
  };

  const handleRemoveFromFavourites = () => {
    if (product) {
      dispatch(removeFromFavouritesTC(product.id));
      setIsFavouriteLocal(false); // Optimistically remove favorite
      navigate(-1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (product?.units && product.units.length > 0) {
      const firstUnit = product.units[0];
      if (
        firstUnit.verbose_properties &&
        firstUnit.verbose_properties.length > 0
      ) {
        setSelectedProperty(firstUnit.verbose_properties[0].property_id);
      } else {
        setSelectedProperty(null);
      }
    }
  }, [product]);

  useEffect(() => {
    setIsFavouriteLocal(isFavourite);
  }, [isFavourite]);

  // Early return after hooks are declared
  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-detail">
      <GoBackButton />
      <div className="product-images">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          modules={[Navigation]}
          scrollbar={{ draggable: true }}
        >
          {mainUnit?.images?.map((image) => (
            <SwiperSlide key={image.id}>
              <img
                key={image.id}
                src={mainURL + image.image}
                alt={product.name}
                onClick={() => openImageModal(mainURL + image.image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="product-detail-info">
        <p className="name">
          {product.name}{' '}
          <div className="favourite-item">
            {isFavouriteLocal ? (
              <FavoriteIcon onClick={handleRemoveFromFavourites} />
            ) : (
              <FavoriteBorderIcon onClick={handleAddToFavourites} />
            )}
          </div>
        </p>
        <div className="price">{product.price} тг</div>
        <div className="description">{product.description}</div>

        {selectedProperty && (
          <div className="product-detail-types">
            {product.units.map((unit) => (
              <button
                key={unit.id}
                className={`product-detail-type ${
                  selectedProperty ===
                  unit.verbose_properties[0].property_value_id
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  handlePropertyChange(
                    unit.verbose_properties[0].property_value_id
                  )
                }
                disabled={unit.num_in_stock < 1}
              >
                {unit.verbose_properties[0].name}:{' '}
                {unit.verbose_properties[0].value}
              </button>
            ))}
          </div>
        )}
        <br />
        <AddToCartButton product={product} />
      </div>

      {modalImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation
              loop
              modules={[Navigation]}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {mainUnit?.images?.map((image) => (
                <SwiperSlide key={image.id}>
                  <img
                    key={image.id}
                    src={mainURL + image.image}
                    alt={product.name}
                    className="fullscreen-image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
